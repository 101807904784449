import {KeyboardArrowDown, KeyboardArrowRight} from '@mui/icons-material'
import {TableRow, Box, IconButton, Theme} from '@mui/material'
import {SxProps} from '@mui/system'
import {isEmpty} from 'lodash'
import React, {useState} from 'react'

import {customThemeConstants} from '../../HProduceTheme'

import {CollapsableTableCell} from './CollapsableTableCell'

interface Item<T> {
  id: string | number
  subItems?: T[]
}

export type CollapsableRowItem = Item<CollapsableRowItem>

export type CollabsebleRowRenderFn<T> = (
  item: T,
  columnIndex: number,
  columnKey: string
) => React.ReactNode

export interface ItemProps<T> {
  onClick?: (item: T) => void
}

export interface CollapsableRowProps<T extends Item<T>> {
  // The expand/collapse state can be controlled by the client
  // with these two functions. Either use both of them or none.
  isItemExpanded?: (item: T) => boolean
  onExpandToggleClicked?: (item: T) => void
  hidden?: boolean
  depth?: number
  item: T
  columnKeys: string[]
  renderCell: CollabsebleRowRenderFn<T>
  itemPropsFn?: (item: T) => ItemProps<T>
  collapseToggleTestIdGenerator?: (itemId: string | number) => string
  getCellStyle?: (itemId: string) => SxProps<Theme> | undefined
}

export const CollapsableRow = <T extends Item<T>>({
  isItemExpanded,
  onExpandToggleClicked,
  hidden = false,
  depth = 0,
  item,
  columnKeys,
  renderCell,
  itemPropsFn,
  collapseToggleTestIdGenerator,
  getCellStyle
}: CollapsableRowProps<T>) => {
  const [expanded, setExpanded] = useState(false)
  const isExpanded = isItemExpanded?.(item) ?? expanded
  const showRow = !hidden
  const columnCount = columnKeys.length
  const onToggle = () => {
    if (onExpandToggleClicked) {
      onExpandToggleClicked(item)
    } else {
      setExpanded((ex) => !ex)
    }
  }

  if (columnCount < 1) {
    return <TableRow />
  }

  const itemProps = itemPropsFn?.(item) ?? {}

  return (
    <>
      <TableRow
        onClick={() => (itemProps.onClick ? itemProps.onClick(item) : onToggle())}
        sx={{
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: customThemeConstants().palette.primaryBackgroundColor
          }
        }}
      >
        <CollapsableTableCell open={showRow}>
          <Box sx={{ml: depth * 3, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}>
            {!isEmpty(item.subItems) && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={itemProps.onClick ? onToggle : undefined}
                color="primary"
                sx={{
                  ml: -1,
                  mr: 1,
                  verticalAlign: 'bottom',
                  p: 0,
                  height: '1em',
                  width: '1em'
                }}
                data-test-id={collapseToggleTestIdGenerator?.(item.id)}
              >
                {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
            )}
            {renderCell(item, 0, columnKeys[0])}
          </Box>
        </CollapsableTableCell>
        {Array.from({length: columnCount - 1}, (_, i) => i + 1).map((j) => (
          <CollapsableTableCell
            key={columnKeys[j]}
            open={showRow}
            sx={getCellStyle && showRow ? getCellStyle(columnKeys[j]) : undefined}
          >
            {renderCell(item, j, columnKeys[j])}
          </CollapsableTableCell>
        ))}
      </TableRow>
      {item.subItems &&
        item.subItems.map((child) => (
          <CollapsableRow
            key={child.id}
            item={child}
            hidden={!isExpanded || hidden}
            depth={depth + 1}
            columnKeys={columnKeys}
            renderCell={renderCell}
            collapseToggleTestIdGenerator={collapseToggleTestIdGenerator}
            itemPropsFn={itemPropsFn}
            isItemExpanded={isItemExpanded}
            onExpandToggleClicked={onExpandToggleClicked}
            getCellStyle={getCellStyle}
          />
        ))}
    </>
  )
}
