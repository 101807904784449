import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {Button, IconButton, Box, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Step, TooltipRenderProps} from 'react-joyride'

type StepTooltipProps = TooltipRenderProps & {
  index: number
  size: number
  step: Step
  isLastStep: boolean
}

export interface StepsProps {
  steps: Step[]
  runJoyride: boolean
}

export const StepTooltip: React.FC<StepTooltipProps> = ({
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep
}) => {
  const {t} = useTranslation()
  return (
    <Stack
      {...tooltipProps}
      spacing={2}
      sx={({spacing, palette}) => ({
        backgroundColor: palette.grey['900'],
        color: palette.common.white,
        py: 2,
        px: 1,
        width: spacing(40),
        borderRadius: spacing(1)
      })}
    >
      <Stack direction="row" alignItems="center" sx={{px: 1}} justifyContent="space-between">
        {step.title && (
          <Typography variant="subtitle1" sx={{color: ({palette}) => palette.common.white}}>
            {step.title}
          </Typography>
        )}
        <IconButton
          {...closeProps}
          sx={(theme) => ({
            color: theme.palette.common.white
          })}
        >
          <CloseIcon sx={{fontSize: 20}} />
        </IconButton>
      </Stack>
      <Box sx={{px: 1}}>{step.content}</Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        {index !== 0 && (
          <Button
            {...backProps}
            variant="text"
            startIcon={<KeyboardArrowLeftIcon sx={{fontSize: 10}} />}
            sx={{textTransform: 'none'}}
          >
            {t('guideStep.previous')}
          </Button>
        )}
        <Button
          {...primaryProps}
          variant="text"
          endIcon={!isLastStep && <KeyboardArrowRightIcon sx={{fontSize: 10}} />}
          sx={{textTransform: 'none'}}
        >
          {isLastStep ? t('guideStep.finish') : t('guideStep.next')}
        </Button>
        <Typography variant="body1">
          {index + 1}/{size}
        </Typography>
      </Stack>
    </Stack>
  )
}
