import Markdown, {MarkdownToJSX} from 'markdown-to-jsx'
import React from 'react'

import {dataTestId as spreadDataTestId} from '../../../common/utils/testingUtils'

interface MarkdownTextProps {
  children: string
  options?: MarkdownToJSX.Options
  ['data-test-id']?: string
}

const defaultOptions: MarkdownToJSX.Options = {
  overrides: {
    a: {
      props: {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    }
  }
}

export const MarkdownText: React.FC<MarkdownTextProps> = ({
  children,
  options,
  'data-test-id': dataTestId
}) => (
  <Markdown
    options={{...defaultOptions, ...options}}
    {...spreadDataTestId(dataTestId ?? 'markdown_text')}
  >
    {children}
  </Markdown>
)
