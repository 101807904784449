import {TFunction} from 'i18next'
import {toLower} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {TimeZone, Dates, isSameDateMoment} from '../../../common'

import {QuickSelectionItem, SelectionItem} from './types'

export const QUICK_SELECTION_IDS = [
  'today',
  'yesterday',
  'last3days',
  'last7days',
  'last30days',
  'last3months'
] as const
export type QuickSelectionId = (typeof QUICK_SELECTION_IDS)[number]

export const defaultQuickSelectionItems = (
  t: TFunction,
  timezone: TimeZone,
  nowTimezoneLocal: Moment
): SelectionItem<QuickSelectionId>[] => {
  if (nowTimezoneLocal.tz() !== timezone)
    throw new Error(
      `Provided date is not in the plant timezone. Expected: ${timezone}, received: ${nowTimezoneLocal.tz()}`
    )
  const startOfToday: Moment = nowTimezoneLocal.startOf('day')
  const endOfToday: Moment = startOfToday.clone().endOf('day')
  const startOfYesterday: Moment = startOfToday.clone().subtract(1, 'day')

  return [
    {
      id: 'today',
      title: t('quickSelection.today'),
      dates: [startOfToday, endOfToday]
    },
    {
      id: 'yesterday',
      title: t('quickSelection.yesterday'),
      dates: [startOfYesterday, startOfYesterday.clone().endOf('day')]
    },
    {
      id: 'last3days',
      title: t('quickSelection.last3days'),
      dates: [startOfToday.clone().subtract(3, 'day'), endOfToday]
    },
    {
      id: 'last7days',
      title: t('quickSelection.last7days'),
      dates: [startOfToday.clone().subtract(7, 'day'), endOfToday]
    },
    {
      id: 'last30days',
      title: t('quickSelection.last30days'),
      dates: [startOfToday.clone().subtract(30, 'day'), endOfToday]
    },
    {
      id: 'last3months',
      title: t('quickSelection.last3months'),
      dates: [startOfToday.clone().subtract(3, 'month'), endOfToday]
    }
  ]
}

export const timeRangeParamToSelectedItem = <T extends string = string>({
  timeRange,
  selectionItems
}: {
  timeRange?: string
  selectionItems: SelectionItem<T>[]
}): QuickSelectionItem | undefined => {
  if (!timeRange) {
    return undefined
  }
  return selectionItems.find(({id}) => toLower(timeRange) === toLower(id))
}

export const timezonedDateValidator = (date: Moment, timezone: string) => {
  if (date.tz() !== timezone) {
    throw new Error(
      `Provided date is not in the plant timezone. Expected: ${timezone}, received: ${date.tz()}`
    )
  }
}

export const localDateTimeRangeToTimeRangeParam = (
  range: [Moment | null, Moment | null],
  timezone: TimeZone
): string | undefined => {
  const [start, end] = range
  if (!start) {
    return undefined
  }
  timezonedDateValidator(start, timezone)
  end && timezonedDateValidator(end, timezone)
  const generatedEnd = end ? end : moment.tz(start, timezone).endOf('day')
  return `[${start.toJSON()},${generatedEnd.toJSON()}]`
}

export const selectionItemInLocalTime = (
  selectionItem: QuickSelectionItem,
  timezone: TimeZone
): QuickSelectionItem => {
  const [start, end] = selectionItem.dates
  return {
    ...selectionItem,
    dates: [moment.tz(start, timezone), end && moment.tz(end, timezone)]
  }
}

export const isSameDay = ([start, end]: [Moment | null, Moment | null]): boolean =>
  isSameDateMoment(start, end)

/**
 * The end should always be the end of the day.
 * So this function takes a range and modifies the end so it is the end of the day
 * @param range
 * @return range that consist of two dates, first is start of day, second is end of day
 */
export const sanitizeLocalDateRange = (range: Dates): [Moment | null, Moment | null] => {
  const timezone = range.startDate?.tz()
  if (timezone && range.endDate) {
    timezonedDateValidator(range.endDate, timezone)
  }
  return [range.startDate, range.endDate ? range.endDate.endOf('day') : null]
}
