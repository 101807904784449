import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {Box, Stack, styled, Typography} from '@mui/material'
import {FC, ReactNode} from 'react'

const StyledButton = styled('button')(({theme}) => ({
  border: 'none',
  backgroundColor: 'transparent',
  color: theme.palette.grey[900],
  cursor: 'pointer',
  padding: '4px',
  '&:disabled': {
    color: theme.palette.grey[300],
    cursor: 'default'
  },
  display: 'flex',
  alignItems: 'center'
}))

type ButtonProps = {
  disabled: boolean
  onClick: () => void
  'data-test-id'?: string
}

type NumberSelectProps = {
  children: ReactNode
  title: string
  minusButtonProps: ButtonProps
  plusButtonProps: ButtonProps
  error?: boolean
}

export const NumberSelect: FC<NumberSelectProps> = ({
  children,
  title,
  plusButtonProps,
  minusButtonProps,
  error
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: (theme) => (error ? theme.palette.error.light : theme.palette.grey[300]),
        borderRadius: '4px',
        width: 164,
        height: 48,
        justifyContent: 'space-between',
        py: 0,
        px: 1
      }}
    >
      <StyledButton {...minusButtonProps}>
        <RemoveIcon />
      </StyledButton>

      <Stack alignItems="center" mx={1}>
        <Typography variant="caption" color="textSecondary">
          {title}
        </Typography>

        {children}
      </Stack>

      <StyledButton {...plusButtonProps}>
        <AddIcon />
      </StyledButton>
    </Box>
  )
}
