import {CircularProgress, IconButton, IconButtonProps} from '@mui/material'
import React, {ForwardedRef} from 'react'

export const LoadingIconButton = React.forwardRef(
  (
    props: IconButtonProps<React.ElementType, {loading?: boolean}>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const {loading = false, disabled, children, component = 'button', ...rest} = props
    return (
      <IconButton ref={ref} disabled={disabled || loading} component={component} {...rest}>
        {loading ? <CircularProgress size={20} /> : children}
      </IconButton>
    )
  }
)
