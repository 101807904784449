import {appUrl, hproduce, PLANT_KPI} from '@hconnect/common/hproduce/config'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import {Typography, Link} from '@mui/material'
import React from 'react'
import {generatePath} from 'react-router'

import {LogoHProduceDarkGreenComponent} from '../logos'

export const HProduceDashboardLink: React.FC<{activePlantId?: string}> = ({activePlantId}) => {
  const redirectUrl = activePlantId
    ? generatePath(appUrl(hproduce.url) + PLANT_KPI, {plantId: activePlantId})
    : appUrl(hproduce.url)

  return (
    <Link color={'textPrimary'} underline={'none'} href={redirectUrl}>
      <Typography
        display="flex"
        alignItems="center"
        p={2}
        sx={{
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <DashboardOutlinedIcon sx={{mr: 1}} fontSize="small" />
        <LogoHProduceDarkGreenComponent />
      </Typography>
    </Link>
  )
}
