import {Box, ButtonProps, Popover, SxProps, Theme, useTheme} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useCallback, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Dates, formatDateLocale} from '../../../common'
import {customThemeConstants} from '../../HProduceTheme'
import {mergeSxs} from '../../utils'

import {Calendar} from './Calendar'
import {DatePickerButton} from './DatePickerButton'
import {DatePickerTextField} from './DatePickerTextField'
import {PickerForm} from './PickerForm'
import {QuickSelection} from './QuickSelection'
import {TabBar} from './TabBar'
import {QuickSelectionItem} from './types'

type DateRange = [Moment | null, Moment | null]

interface Props {
  autoFocus?: boolean
  calendarWrapper?: (innerPicker: React.ReactNode) => React.ReactNode
  sx?: SxProps<Theme>
  date?: Moment | null
  dateRange?: DateRange
  dateExceptions?: Moment[]
  focusEndDate?: boolean
  handleDateChange?: (arg: Moment | null) => void
  handleRangeChange?: (args: Dates, item?: QuickSelectionItem) => void
  isOutsideRange?: (arg: Moment) => boolean
  label?: React.ReactNode
  fullWidth?: boolean
  noQuickSelection?: boolean
  disableCustomSelect?: boolean
  noAutoClose?: boolean
  showDateChange?: boolean
  showDatePickerButton?: boolean
  showDateChangeLabel?: string
  textFormatter?: (d: Moment) => string
  textSx?: SxProps<Theme>
  withForm?: boolean
  selectedQuickSelectItem?: QuickSelectionItem
  quickSelectionItems?: QuickSelectionItem[]
  quickSelectOptionDateTemplate?: (option: QuickSelectionItem) => string
  datePickerButtonWithSimpleLayout?: boolean
  datePickerButtonProps?: ButtonProps
  errorText?: string
  required?: boolean
  disabled?: boolean
  showClearButton?: boolean
  timezone: string
}

type DatePickerProps = Props &
  (
    | {noQuickSelection?: true; disableCustomSelect?: false}
    | {disableCustomSelect?: true; noQuickSelection?: false}
    | {noQuickSelection?: false; disableCustomSelect?: false}
  )

export const datePickerOnBlueSx: SxProps<Theme> = (theme) => ({
  height: 58,
  '& > button': {
    borderColor: customThemeConstants().palette.mediumInputBorder,
    '&:hover': {
      borderColor: theme.palette.common.white
    }
  },
  '& .MuiTypography-root, .MuiSvgIcon-root': {
    color: theme.palette.common.white
  },
  '& .MuiTypography-caption': {
    opacity: customThemeConstants().labelOpacity
  }
})

function getSelectionText(
  selectedStartDate: Moment | null,
  selectedEndDate: Moment | null | undefined,
  isSingleDate: boolean,
  noDateText: string,
  language: string,
  textFormatter?: (d: Moment) => string
) {
  const startText = !selectedStartDate
    ? noDateText
    : formatDateLocale(selectedStartDate, language, textFormatter)

  const endText = !selectedEndDate
    ? noDateText
    : formatDateLocale(selectedEndDate, language, textFormatter)

  return isSingleDate ? startText : `${startText} - ${endText}`
}

const SimpleDatePicker: React.FC<DatePickerProps> = ({
  autoFocus,
  sx,
  calendarWrapper,
  date,
  dateExceptions,
  dateRange = [],
  focusEndDate,
  handleDateChange,
  handleRangeChange,
  isOutsideRange,
  label,
  fullWidth = false,
  noQuickSelection = true,
  noAutoClose,
  showDateChange,
  showDatePickerButton,
  showDateChangeLabel,
  textSx,
  textFormatter,
  withForm,
  selectedQuickSelectItem,
  quickSelectionItems,
  quickSelectOptionDateTemplate,
  datePickerButtonWithSimpleLayout,
  datePickerButtonProps,
  errorText,
  required,
  disabled,
  showClearButton,
  disableCustomSelect,
  timezone
}) => {
  const theme = useTheme()
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const isSingleDate = date !== undefined
  const [startDate, endDate] = dateRange
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
    date || startDate || null
  )
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [showCalendar, setShowCalendar] = useState(!selectedQuickSelectItem || noQuickSelection)
  const noDateText = t('datePicker.chooseDate')

  const selectionText = getSelectionText(
    selectedStartDate,
    selectedEndDate,
    isSingleDate,
    noDateText,
    language,
    textFormatter
  )

  const close = useCallback(() => setOpen(false), [setOpen])

  const calendarWrapperFn = calendarWrapper || ((node: React.ReactNode) => node)

  const handleDateRangeChange = (dates: Dates, item?: QuickSelectionItem) => {
    setSelectedStartDate(dates.startDate || null)
    setSelectedEndDate(dates.endDate || null)

    if (dates.endDate) {
      handleRangeChange?.({startDate: dates.startDate, endDate: dates.endDate}, item)
      !noAutoClose && close()
    }
  }

  const handleSingleDateChange = (date: Moment | null) => {
    if (!date) return
    setSelectedStartDate(date)
    setSelectedEndDate(date)

    handleDateChange?.(date)
    !noAutoClose && close()
  }

  const handleDateClear = showClearButton
    ? () => {
        setSelectedStartDate(null)
        setSelectedEndDate(null)
        handleDateChange?.(null)
        handleRangeChange?.({startDate: null, endDate: null})
      }
    : undefined

  const calendarProps = {
    timezone,
    autoFocus,
    focusEndDate,
    isOutsideRange,
    startDate: selectedStartDate,
    ...(isSingleDate
      ? {onDateChange: handleSingleDateChange, dateExceptions}
      : {endDate: selectedEndDate, onRangeChange: handleDateRangeChange})
  }

  return (
    <Box sx={mergeSxs([fullWidth && {width: '100%'}], sx)}>
      {showDatePickerButton ? (
        <DatePickerButton
          buttonRef={anchorRef}
          focus={open}
          setFocus={setOpen}
          label={selectionText}
          isDateChange={showDateChange}
          isDateChangeLabel={showDateChangeLabel}
          simpleLayout={datePickerButtonWithSimpleLayout}
          customButtonProps={datePickerButtonProps}
        />
      ) : (
        <DatePickerTextField
          buttonRef={anchorRef}
          focus={open}
          setFocus={setOpen}
          text={selectionText}
          label={label}
          textSx={textSx}
          error={errorText}
          required={required}
          disabled={disabled}
          onClearButtonClick={handleDateClear}
          isDateSelected={!!selectedStartDate || !!selectedEndDate}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          id: 'simple-date-picker-popover-paper',
          sx: {
            marginTop: theme.spacing(2),
            color: theme.palette.primary.contrastText,
            // 410px were chosen as a point which date picker will start to overflow
            // below that point, the popover will stretch to full width to buy some space for date picker
            '@media only screen and (max-width: 410px)': {
              left: '0 !important',
              width: '100%',
              maxWidth: '100%',
              '& > div': {
                width: '100%'
              }
            }
          }
        }}
        data-test-id="simple-date-picker-popover"
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 356
          }}
        >
          {!disableCustomSelect && !noQuickSelection && (
            <TabBar setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
          )}

          {showCalendar ? (
            calendarWrapperFn(
              <>
                <Calendar {...calendarProps} />
                {withForm && (
                  <PickerForm
                    applyDateRange={(dates) =>
                      isSingleDate
                        ? handleSingleDateChange(dates.startDate)
                        : handleDateRangeChange(dates)
                    }
                    dateExceptions={dateExceptions}
                    isOutsideRange={isOutsideRange}
                    isSingleDate={isSingleDate}
                    endDate={selectedEndDate}
                    startDate={selectedStartDate}
                    timeZone={timezone}
                  />
                )}
              </>
            )
          ) : (
            <QuickSelection
              handleItemClick={handleDateRangeChange}
              selectedItem={selectedQuickSelectItem}
              dateCustomTemplate={quickSelectOptionDateTemplate}
              items={quickSelectionItems}
              timezone={timezone}
            />
          )}
        </Box>
      </Popover>
    </Box>
  )
}

export {SimpleDatePicker}
export type {Props as SimpleDatePickerProps}
