import {Box, Typography} from '@mui/material'
import React from 'react'

import {PageContainer, TextOnBackground} from '../components'

interface BaseErrorPageProps {
  caption: string
  description: string
  illustration?: React.ReactNode
  dataTestId?: string
  children?: React.ReactNode
}

export const BaseErrorPage: React.FC<BaseErrorPageProps> = ({
  caption,
  description,
  illustration,
  dataTestId = 'base_error_page',
  children
}) => {
  return (
    <PageContainer flex={1} display={'flex'} justifyContent={'center'} data-test-id={dataTestId}>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        {illustration}
        <Box my={4} maxWidth={400}>
          <Typography mb={3} variant={'h1'} align={'center'}>
            {caption}
          </Typography>
          <TextOnBackground contrast={'low'} align={'center'}>
            {description}
          </TextOnBackground>
        </Box>
        {children && (
          <Box display={'flex'} justifyContent={'center'}>
            {children}
          </Box>
        )}
      </Box>
    </PageContainer>
  )
}
