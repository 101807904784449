import {Logout, ArrowBack} from '@mui/icons-material'
import {Button, Stack} from '@mui/material'
import React from 'react'

import {buttonOnBlueSx} from '../components'

import {BaseErrorPage} from './BaseErrorPage'
import {ReactComponent as Illustration} from './illustration403.svg'

interface Error403PageProps {
  caption: string
  description: string
  onLogout: () => void
  logoutCaption?: string
  onGoBack?: () => void
  goBackCaption?: string
  dataTestId?: string
}

export const Error403Page: React.FC<Error403PageProps> = ({
  caption,
  description,
  onLogout,
  logoutCaption = 'Logout',
  onGoBack,
  goBackCaption = 'Back to last page',
  dataTestId = 'error_403_page'
}) => (
  <BaseErrorPage
    caption={caption}
    description={description}
    illustration={<Illustration />}
    dataTestId={dataTestId}
  >
    <Stack direction="row" spacing={2}>
      {onGoBack && (
        <Button
          sx={buttonOnBlueSx}
          startIcon={<ArrowBack />}
          onClick={onGoBack}
          data-test-id={'error_page_403_go_back_btn'}
        >
          {goBackCaption}
        </Button>
      )}
      <Button
        sx={buttonOnBlueSx}
        startIcon={<Logout />}
        onClick={onLogout}
        data-test-id={'error-page-403-logout-btn'}
      >
        {logoutCaption}
      </Button>
    </Stack>
  </BaseErrorPage>
)
