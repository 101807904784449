import {TextField} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import moment, {Moment} from 'moment-timezone'
import {useCallback, useEffect, useState, FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Iso8601} from '../../../common'

import {NumberSelect} from './NumberSelect'

type TimeStepperProps = {
  dateUtc: Iso8601
  timezone: string
  dateUtcBound: [Iso8601, Iso8601]
  onChange?: (dateUtc: Iso8601) => void
  'data-test-id'?: string
}

export const TimeStepper: FC<TimeStepperProps> = ({
  dateUtc,
  timezone,
  dateUtcBound,
  onChange,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const [selectedTime, _setSelectedTime] = useState(moment.utc(dateUtc).tz(timezone))
  const [minTime, maxTime] = dateUtcBound.map((date) => moment.utc(date).tz(timezone))
  const [open, setOpen] = useState(false)

  useEffect(() => {
    _setSelectedTime(moment.utc(dateUtc).tz(timezone))
  }, [dateUtc, timezone])

  const setSelectedTime = useCallback(
    (date: Moment) => {
      _setSelectedTime(date)
      if (onChange) {
        onChange(date.toISOString())
      }
    },
    [onChange]
  )

  const getPreviousTime = () => {
    return selectedTime.clone().subtract(10, 'minutes')
  }

  const getNextTime = () => {
    return selectedTime.clone().add(10, 'minutes')
  }

  const isSelectedTimeValid = selectedTime.isValid() && selectedTime.isBetween(minTime, maxTime)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NumberSelect
        title={t('timeStepper.label')}
        plusButtonProps={{
          onClick: () => setSelectedTime(getNextTime()),
          disabled: getNextTime().isAfter(maxTime),
          'data-test-id': `${dataTestId}-plus`
        }}
        minusButtonProps={{
          onClick: () => setSelectedTime(getPreviousTime()),
          disabled: getPreviousTime().isBefore(minTime),
          'data-test-id': `${dataTestId}-minus`
        }}
        error={!isSelectedTimeValid}
      >
        <TimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={selectedTime}
          onChange={(newValue) => {
            if (newValue) {
              setSelectedTime(newValue)
            }
          }}
          minTime={minTime}
          maxTime={maxTime}
          inputFormat="HH:mm"
          renderInput={({inputRef, inputProps}) => (
            <TextField
              data-test-id={dataTestId}
              ref={inputRef}
              inputProps={inputProps}
              sx={{
                width: '80px',
                backgroundColor: 'transparent',
                margin: 0,

                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '& .MuiInputBase-input': {
                  padding: 0,
                  textAlign: 'center'
                }
              }}
            />
          )}
        />
      </NumberSelect>
    </LocalizationProvider>
  )
}
