import {Box, Theme, useTheme} from '@mui/material'
import React from 'react'

export const BUTTON_SIZE = 44
const CURRENT_DAY_DOT_SIZE = 5

const rangeBGColor = 'primary.main'
const disabledRangeBGColor = '#cccccc90'

const afterStyle = () => ({
  position: 'absolute',
  content: '""',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})
const beforeStyle = () => ({
  position: 'absolute',
  content: '""',
  zIndex: 1,
  top: '75%',
  left: '50%',
  width: CURRENT_DAY_DOT_SIZE,
  height: CURRENT_DAY_DOT_SIZE,
  borderRadius: '50%',
  transform: 'translateX(-50%)'
})

type DisabledDataStatus = {
  inDisabledRange: boolean
  isDisabledRangeStart: boolean
  isDisabledRangeEnd: boolean
  isDisabled: boolean
}
type DataStatus = DisabledDataStatus & {
  inRange: boolean
  isRangeEnd: boolean
  isRangeStart: boolean
  selected: boolean
  isCurrentDay: boolean
  isCurrentMonth: boolean
  theme: Theme
}

const generateDisabledStyles = ({
  isDisabledRangeStart,
  isDisabledRangeEnd,
  inDisabledRange,
  isDisabled
}: DisabledDataStatus) => ({
  ...(inDisabledRange && {
    backgroundColor: 'transparent',
    '&:after': {
      ...afterStyle(),
      backgroundColor: disabledRangeBGColor,
      borderRadius: '0'
    }
  }),
  ...(isDisabled && {
    backgroundColor: disabledRangeBGColor,
    color: 'black',
    cursor: 'not-allowed'
  }),
  ...(isDisabledRangeStart && {
    backgroundColor: 'transparent',

    '&:after': {
      ...afterStyle(),
      borderTopLeftRadius: BUTTON_SIZE / 2,
      borderBottomLeftRadius: BUTTON_SIZE / 2,
      backgroundColor: disabledRangeBGColor
    }
  }),
  ...(isDisabledRangeEnd && {
    backgroundColor: 'transparent',

    '&:after': {
      ...afterStyle(),
      borderTopRightRadius: BUTTON_SIZE / 2,
      borderBottomRightRadius: BUTTON_SIZE / 2,
      backgroundColor: disabledRangeBGColor
    }
  })
})

const generateStyles = ({
  inRange,
  isRangeStart,
  selected,
  isDisabledRangeStart,
  isDisabledRangeEnd,
  isRangeEnd,
  inDisabledRange,
  isCurrentDay,
  isCurrentMonth,
  isDisabled,
  theme
}: DataStatus) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '50%',
    ...(isCurrentDay && {
      '&:before': {
        ...beforeStyle(),
        backgroundColor: theme.palette.common.white
      }
    })
  },
  ...(isCurrentMonth && {
    color: theme.palette.text.primary
  }),
  ...(isCurrentDay && {
    '&:before': {
      ...beforeStyle(),
      backgroundColor: theme.palette.primary.main,
      ...((selected || inRange || isRangeEnd || isRangeStart) && {
        backgroundColor: theme.palette.common.white
      })
    }
  }),
  ...(inRange && {
    color: theme.palette.common.white,
    '&:after': {
      ...afterStyle(),
      backgroundColor: rangeBGColor,
      borderRadius: '0'
    }
  }),
  ...(isRangeEnd && {
    '&:after': {
      ...afterStyle(),
      borderWidth: BUTTON_SIZE / 2,
      borderColor: rangeBGColor,
      borderStyle: 'solid',
      borderTopRightRadius: BUTTON_SIZE / 2,
      borderBottomRightRadius: BUTTON_SIZE / 2,
      backgroundColor: 'transparent'
    }
  }),
  ...(isRangeStart && {
    '&:after': {
      ...afterStyle(),
      borderWidth: BUTTON_SIZE / 2,
      borderColor: rangeBGColor,
      borderStyle: 'solid',
      borderTopLeftRadius: BUTTON_SIZE / 2,
      borderBottomLeftRadius: BUTTON_SIZE / 2,
      backgroundColor: 'transparent',
      ...(isRangeEnd && {
        borderTopRightRadius: BUTTON_SIZE / 2,
        borderBottomRightRadius: BUTTON_SIZE / 2
      })
    }
  }),
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }),
  ...generateDisabledStyles({
    isDisabledRangeStart,
    isDisabledRangeEnd,
    inDisabledRange,
    isDisabled
  })
})

interface Props {
  day: number
  handleHover: () => void
  handleSelect: () => void
  inRange: boolean
  inDisabledRange: boolean
  isDisabled: boolean
  isCurrentMonth: boolean
  isDisabledRangeStart: boolean
  isDisabledRangeEnd: boolean
  isRangeEnd: boolean
  isRangeStart: boolean
  selected: boolean
  isCurrentDay: boolean
}

const DateButton: React.FC<Props> = ({
  day,
  handleHover,
  handleSelect,
  inDisabledRange,
  inRange,
  isDisabled,
  isDisabledRangeStart,
  isDisabledRangeEnd,
  isCurrentMonth,
  isRangeEnd,
  isRangeStart,
  selected,
  isCurrentDay
}) => {
  const theme = useTheme()

  return (
    <Box
      data-test-id={`date-button-${day}-${isCurrentMonth ? 'current' : 'next-or-prev'}`}
      component="button"
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '50%',
        height: BUTTON_SIZE,
        width: BUTTON_SIZE,
        fontSize: 16,
        padding: 0,
        cursor: 'pointer',
        color: theme.palette.text.secondarySoft,
        position: 'relative',
        ...generateStyles({
          inRange,
          isRangeStart,
          selected,
          isDisabledRangeStart,
          isDisabledRangeEnd,
          isRangeEnd,
          inDisabledRange,
          isCurrentDay,
          isCurrentMonth,
          isDisabled,
          theme
        })
      }}
      disabled={isDisabled}
      onClick={handleSelect}
      onFocus={handleHover}
      onMouseOver={handleHover}
    >
      <Box
        component="span"
        sx={{
          zIndex: 1,
          position: 'relative'
        }}
      >
        {day}
      </Box>
    </Box>
  )
}

export {DateButton}
