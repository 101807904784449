import {useTheme, Theme} from '@mui/material'
import {CSSProperties} from 'react'

const link = (theme: Theme) =>
  ({
    ...theme.typography.h4,
    color: theme.palette.text.primary,
    textDecorationLine: 'none',
    paddingBottom: '4px',
    fontSize: '16px',
    fontWeight: 500
  }) as CSSProperties

const desktopLink = (theme: Theme) =>
  ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }) as CSSProperties

const activeLink = (theme: Theme): CSSProperties => ({
  color: theme.palette.primary.light,
  borderBottomColor: theme.palette.primary.light,
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  pointerEvents: 'none'
})
const disabledLink = (theme: Theme): CSSProperties => ({
  color: theme.palette.secondary.main,
  borderBottomColor: 'none',
  pointerEvents: 'none'
})

export const useNavigationLinkStyles = () => {
  const theme = useTheme()
  return {
    link: link(theme),
    desktopLink: desktopLink(theme),
    activeLink: activeLink(theme),
    disabledLink: disabledLink(theme)
  }
}
