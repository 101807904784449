import {GuidedTourStatus} from '../types/GuidedTour.enums'

const localStorage = window.localStorage

// Gets the status of a guided tour from local storage
export const getStatus = (key: string): GuidedTourStatus => {
  const guidedToursStatusRaw = localStorage.getItem(key)
  if (!guidedToursStatusRaw) return GuidedTourStatus.NEW
  if (Object.values(GuidedTourStatus).includes(guidedToursStatusRaw as GuidedTourStatus)) {
    return guidedToursStatusRaw as GuidedTourStatus
  } else return GuidedTourStatus.NEW
}

export const getStatusesOfTours = (keys: string[]): Record<string, GuidedTourStatus> =>
  keys.reduce(
    (acc, key) => {
      acc[key] = getStatus(key)
      return acc
    },
    {} as Record<string, GuidedTourStatus>
  )

// Sets the status of a guided tour in local storage from the key and status
export const setTourStatus = (key: string, status: GuidedTourStatus) =>
  localStorage.setItem(key, status)
