import {Box, SxProps, Theme} from '@mui/material'
import React from 'react'

import {flagPathByCountryCode} from '../../common'

interface FlagProps {
  countryCode: string
  alt?: string
  height?: number | string
  width?: number | string
  sx?: SxProps<Theme>
}

export const Flag: React.FC<FlagProps> = ({alt, countryCode, height = 21, width, sx}) => {
  const src = flagPathByCountryCode(countryCode)
  if (!src) {
    return null
  }

  return (
    <Box
      bgcolor="background.paper"
      component={'img'}
      src={src}
      alt={alt ?? countryCode}
      height={height}
      width={width}
      borderRadius="2px"
      sx={sx}
    />
  )
}
