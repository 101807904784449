/** @jsxImportSource @emotion/react */
import {AppName, AppPlantSelectOption, PlantId} from '@hconnect/common/hproduce/types'
import {Check} from '@mui/icons-material'
import {Box, Divider, MenuItem, MenuList, styled} from '@mui/material'
import React from 'react'

import {AppLogo} from '../../common'

export type ProductSelectProps = {
  activeAppName?: AppName | 'HProduce'
  activePlantId?: PlantId
  'data-test-id'?: string
  options: AppPlantSelectOption[]
}

export const StyledAnchor = styled('a')<{isActive: boolean}>(({theme, isActive}) => ({
  textDecoration: 'none',
  '& $item': isActive
    ? {
        color: theme.palette.primary.main
      }
    : {}
}))

export const ProductSelect: React.FC<ProductSelectProps> = ({
  options,
  activeAppName,
  activePlantId,
  'data-test-id': dataTestId
}) => {
  return (
    <MenuList disablePadding data-test-id={dataTestId}>
      {options.map((option, i) => {
        const {appName} = option
        const isActive = option.plantId === activePlantId && appName === activeAppName

        return (
          <StyledAnchor
            isActive={isActive}
            key={option.appName}
            href={option.url}
            data-test-id={dataTestId ? `${appName}-${dataTestId}` : appName}
          >
            <MenuItem
              sx={(theme) => ({
                color: theme.palette.text.primary,
                padding: theme.spacing(2, 2),
                '& svg': {
                  mr: 1
                },
                '& +.MuiDivider-root': {
                  my: 0
                }
              })}
            >
              <AppLogo name={appName} size={16} />
              <Box component={'span'} flex={1}>
                {appName}
              </Box>
              {activeAppName === appName && <Check />}
            </MenuItem>
            {i < options.length - 1 && <Divider orientation="horizontal" sx={{my: 0, mx: 2}} />}
          </StyledAnchor>
        )
      })}
    </MenuList>
  )
}
