import {BoxProps, Box} from '@mui/material'
import React, {forwardRef} from 'react'

export const CardBox = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <Box
    ref={ref}
    color="text.primary"
    borderRadius={1}
    pt={props.pt || {xs: 2, md: 3}}
    pb={props.pb || {xs: 2, md: 3}}
    px={props.px || {xs: 2, md: 3}}
    bgcolor="background.paper"
    boxShadow={1}
    {...props}
  />
))
