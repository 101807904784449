import CloseIcon from '@mui/icons-material/Close'
import DateRangeIcon from '@mui/icons-material/DateRange'
import {Box, useTheme, Typography, Button, Theme, SxProps, IconButton} from '@mui/material'
import React, {RefObject} from 'react'
import {useTranslation} from 'react-i18next'

import {customThemeConstants} from '../../HProduceTheme'

interface Props {
  focus: boolean
  setFocus: (focus: boolean) => void
  text: string
  label?: React.ReactNode
  textClasses?: SxProps<Theme>
  buttonRef: RefObject<HTMLButtonElement>
  error?: string
  required?: boolean
  disabled?: boolean
  onClearButtonClick?: () => void
  isDateSelected?: boolean
}

const DatePickerTextField: React.FC<Props> = ({
  focus,
  setFocus,
  label,
  text,
  textClasses,
  buttonRef,
  error,
  required,
  onClearButtonClick,
  isDateSelected,
  disabled,
  ...otherProps
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  return (
    <>
      <Button
        sx={[
          {
            width: 'inherit',
            justifyContent: 'flex-start',
            height: 'inherit',
            borderWidth: 1,
            paddingX: 1.375,
            paddingY: 0.375,

            '&:hover': {
              borderColor: `${customThemeConstants().palette.mediumInputBorder} !important`
            }
          },
          ...(Array.isArray(textClasses) ? textClasses : [textClasses])
        ]}
        ref={buttonRef}
        data-test-id="date-picker-toggle-button"
        onClick={() => {
          setFocus(!focus)
        }}
        disabled={disabled}
        {...otherProps}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{flexShrink: 1, whiteSpace: 'nowrap', width: 'inherit'}}
        >
          <Box
            sx={{
              textAlign: 'left',
              cursor: 'pointer',
              flexShrink: 1,
              whiteSpace: 'nowrap',
              width: 'inherit'
            }}
          >
            <Typography variant="caption" color={error ? 'error' : 'grey.600'}>
              {label ?? t('datePicker.label')}
              {required && ' *'}
            </Typography>
            <Box
              sx={{
                py: theme.spacing(0.25),
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 'auto'
              }}
            >
              <Box display="flex" flexDirection="row">
                <DateRangeIcon
                  sx={{
                    background: 'transparent',
                    color: theme.palette.secondary.dark,
                    marginRight: 1,
                    ...(error && {color: theme.palette.error.main})
                  }}
                  fontSize="small"
                />
                <Typography
                  variant="h4"
                  sx={[
                    {
                      fontSize: 16,
                      color: 'text.primary'
                    },
                    !!error && {color: 'error'},
                    !!disabled && {color: 'text.disabled'}
                  ]}
                  noWrap
                  width={'inherit'}
                >
                  {text}
                </Typography>
              </Box>
            </Box>
          </Box>
          {isDateSelected && onClearButtonClick && (
            <Box>
              <IconButton
                data-test-id={'clear-date-picker-btn'}
                onClick={(event) => {
                  onClearButtonClick()
                  event.stopPropagation()
                }}
                sx={{backgroundColor: 'common.white', marginRight: 1}}
              >
                <CloseIcon
                  sx={{
                    background: 'transparent',
                    color: theme.palette.secondary.dark,
                    ...(error && {color: theme.palette.error.main})
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          )}
        </Box>
      </Button>
      {error && (
        <Typography variant="caption" color="error" mt={0.625} ml={1.75} display="flex">
          {error}
        </Typography>
      )}
    </>
  )
}

export {DatePickerTextField}
