import {Box, Switch, FormControlLabel, Typography} from '@mui/material'
import {noop} from 'lodash'
import React, {useState} from 'react'

import {Dates} from '../../../common'

import {
  localDateTimeRangeToTimeRangeParam,
  timeRangeParamToLocalTime,
  selectionItemInLocalTime,
  isSameDay,
  sanitizeLocalDateRange,
  timeRangeParamToSelectedItem
} from './dateRangePickerHelpers'
import {datePickerOnBlueSx, SimpleDatePicker} from './SimpleDatePicker'
import {QuickSelectionItem, SelectionItem} from './types'

interface DateRangePickerProps<T extends string> {
  timezone: string // time zone of the start and end of days
  singleDateSwitchLabel?: string
  timeRangeParam?: string // can be quick selection ID or serialized time range
  onTimeRangeParamChange?: (timeRange) => void
  selectionItems: SelectionItem<T>[]
  disableCustomSelect?: boolean
  fullWidth?: boolean
}

export const DateRangePicker = <T extends string>({
  timezone,
  timeRangeParam: timeRange,
  onTimeRangeParamChange: setTimeRange = noop,
  singleDateSwitchLabel,
  selectionItems,
  disableCustomSelect,
  fullWidth
}: DateRangePickerProps<T>): React.ReactElement => {
  const timeRangeLocalTime = timeRangeParamToLocalTime({timeRange, timezone, selectionItems})
  const [singleDate, setSingleDate] = useState(isSameDay(timeRangeLocalTime))
  const selectedItem = timeRangeParamToSelectedItem({
    timeRange,
    selectionItems: selectionItems
  })
  const calendarWrapper: (innerPicker: React.ReactNode) => React.ReactNode = (innerPicker) => {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Box flex={1}>{innerPicker}</Box>
        <FormControlLabel
          control={
            <Switch checked={singleDate} onChange={(_, checked) => setSingleDate(checked)} />
          }
          label={<Typography variant={'caption'}>{singleDateSwitchLabel}</Typography>}
        />
      </Box>
    )
  }

  const handleRangeChange = (range: Dates<Date>, item: QuickSelectionItem | undefined) => {
    const localTimeRange = sanitizeLocalDateRange(range)
    setSingleDate(isSameDay(localTimeRange))
    setTimeRange(item ? item.id : localDateTimeRangeToTimeRangeParam(localTimeRange, timezone))
  }

  return (
    <SimpleDatePicker
      calendarWrapper={calendarWrapper}
      disableCustomSelect={disableCustomSelect}
      sx={datePickerOnBlueSx}
      dateRange={singleDate ? undefined : timeRangeLocalTime}
      date={singleDate ? timeRangeLocalTime[0] : undefined}
      handleRangeChange={handleRangeChange}
      handleDateChange={(date) => {
        setTimeRange(localDateTimeRangeToTimeRangeParam([date, null], timezone))
      }}
      noQuickSelection={false}
      selectedQuickSelectItem={
        selectedItem ? selectionItemInLocalTime(selectedItem, timezone) : undefined
      }
      quickSelectionItems={selectionItems.map((item) => selectionItemInLocalTime(item, timezone))}
      fullWidth={fullWidth}
    />
  )
}
