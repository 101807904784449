import {Theme, SxProps} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'

import {customThemeConstants} from '../../HProduceTheme'

export const buttonOnBlueSx: SxProps<Theme> = (theme) => ({
  border: 1,
  borderColor: customThemeConstants().palette.mediumButtonBorder,
  backgroundColor: customThemeConstants().palette.mediumButtonBackground,
  color: theme.palette.common.white,
  padding: 2,
  '&:hover': {
    borderColor: customThemeConstants().palette.mediumButtonBorder,
    backgroundColor: customThemeConstants().palette.mediumButtonBackgroundHover
  },
  '&:active': {
    backgroundColor: customThemeConstants().palette.mediumButtonBackgroundDown
  }
})

export const buttonSx = (theme: Theme): SystemStyleObject<Theme> => ({
  padding: 1,
  borderColor: theme.palette.grey[300],
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.background.default,
  '&:hover': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.background.default
  },
  '&:active': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.background.default
  }
})

export const buttonCheckedSx = (theme: Theme): SystemStyleObject<Theme> => ({
  padding: 1,
  borderColor: theme.palette.background.default,
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.background.paper,
  '&:hover': {
    borderColor: theme.palette.background.default,
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.background.paper
  },
  '&:active': {
    borderColor: theme.palette.background.default,
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.background.paper
  }
})
