import {useTheme} from '@mui/material'
import React, {useState, useEffect} from 'react'
import Joyride, {CallBackProps, ACTIONS, EVENTS} from 'react-joyride'

import type {GuidedTour} from '../types'

import {StepTooltip} from './StepTooltip'

export interface TourStepsProps {
  activeTourKey: string | undefined
  activeTour: GuidedTour | undefined
  markTourAsCompleted: (tourKey: string) => void
  setActiveTour: (tourKey: string | undefined) => void
}
export const TourSteps: React.FC<TourStepsProps> = ({
  activeTour,
  markTourAsCompleted,
  setActiveTour,
  activeTourKey
}: TourStepsProps) => {
  const {palette} = useTheme()
  const [isTourPaused, setIsTourPaused] = useState(true)
  const [stepIndex, setStepIndex] = useState(0)

  useEffect(() => {
    let cleaned = false
    if (activeTourKey) {
      ;(async () => {
        const onTourStartedPromise = activeTour?.onTourStarted?.()
        if (onTourStartedPromise) {
          await onTourStartedPromise
        }
        if (cleaned) {
          return
        }
        setIsTourPaused(false)
      })()
    }

    return () => {
      cleaned = true
    }
    // we need to run this effect only when active tour key changes, active tour could be changing if provided tours not memoized properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTourKey])

  if (!activeTour || !activeTourKey) return null
  const {steps, onTourClosed, onTourCompleted} = activeTour

  const completeTour = () => {
    markTourAsCompleted(activeTourKey)
    onTourCompleted?.()
    setStepIndex(0)
    setActiveTour(undefined)
  }

  const closeTour = () => {
    markTourAsCompleted(activeTourKey)
    onTourClosed?.()
    setActiveTour(undefined)
  }

  const runAsyncCallback = async (cb: () => Promise<void> | void) => {
    setIsTourPaused(true)
    await cb()
    setIsTourPaused(false)
  }

  const handleTourStart = () => {
    const [firstStep] = steps
    if (firstStep?.onGoNextToStep) {
      void runAsyncCallback(firstStep.onGoNextToStep)
    }
  }

  const handlePrevStep = (currentIndex: number) => {
    const prevStep = steps[currentIndex - 1]
    setStepIndex(currentIndex - 1)
    if (prevStep?.onGoPrevToStep) {
      void runAsyncCallback(prevStep.onGoPrevToStep)
    }
  }
  const handleNextStep = (currentIndex: number) => {
    const nextStep = steps[currentIndex + 1]
    setStepIndex(currentIndex + 1)
    if (nextStep?.onGoNextToStep) {
      void runAsyncCallback(nextStep.onGoNextToStep)
    }
  }
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {index, action, type} = data
    if (type.includes(EVENTS.TOUR_START)) {
      return handleTourStart()
    }
    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      if (action === ACTIONS.NEXT) {
        return handleNextStep(index)
      }
      if (action === ACTIONS.PREV) {
        return handlePrevStep(index)
      }
      if (action === ACTIONS.CLOSE) {
        return closeTour()
      }
    }
    if (type.includes(EVENTS.TOUR_END)) {
      return completeTour()
    }
  }

  return (
    <Joyride
      styles={{options: {arrowColor: palette.grey[900], zIndex: 2000}}}
      floaterProps={{
        styles: {
          arrow: {
            spread: 10,
            length: 6
          }
        }
      }}
      tooltipComponent={StepTooltip}
      steps={steps.map((step) => ({...step, disableBeacon: true}))}
      run={!isTourPaused}
      callback={handleJoyrideCallback}
      continuous
      showProgress
      disableOverlayClose
      stepIndex={stepIndex}
    />
  )
}
