import {Box, Typography, SxProps, Theme, Stack} from '@mui/material'
import {toString} from 'lodash'
import React, {ReactNode} from 'react'

import {mergeSx} from '../../utils'

import {BasicGaugeBar} from './BasicGaugeBar'

interface GaugeBarProps {
  value: number
  color: string
  onClick?: () => void
  minValue?: number
  maxValue?: number
  format?: (value: number) => string
  minLabelWidth?: number
  barHeight?: number
  headerTemplate?: ReactNode
  customSubtitle?: ReactNode
  sx?: SxProps<Theme>
  'data-test-id'?: string
}

export const GaugeBar: React.FC<GaugeBarProps> = ({
  value,
  color,
  headerTemplate,
  onClick,
  minValue = 0,
  maxValue = 100,
  format = toString,
  minLabelWidth = 32,
  barHeight,
  sx,
  customSubtitle,
  'data-test-id': dataTestId
}) => (
  <Stack
    data-test-id={dataTestId}
    sx={{gap: 0.5, ...(onClick && {cursor: 'pointer'})}}
    onClick={onClick}
  >
    {headerTemplate}
    <Box
      sx={mergeSx(
        {
          display: 'flex',
          alignItems: 'center'
        },
        sx
      )}
    >
      <Box sx={{flex: 1}}>
        <BasicGaugeBar
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          color={color}
          barHeight={barHeight}
        />
      </Box>
    </Box>
    {customSubtitle || (
      <Typography variant="subtitle2" minWidth={minLabelWidth}>
        {format(value)}
      </Typography>
    )}
  </Stack>
)
