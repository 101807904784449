import {PlantId} from '@hconnect/common/hproduce/types'
import {Box, FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export type Plant = {
  plantId: PlantId
  plantName: string
}

type PlantSelectProps = {
  activePlantId: PlantId
  'data-test-id': string
  plants: Plant[]
  onChange: (next: PlantId) => void
}

export const PlantSelect: React.FC<PlantSelectProps> = ({
  activePlantId,
  'data-test-id': dataTestId,
  plants,
  onChange
}) => {
  const {t} = useTranslation()

  return (
    <Box p={2} data-test-id={dataTestId}>
      <FormControl
        variant="standard"
        sx={(theme) => ({
          width: '100%',
          p: 1,
          background: theme.palette.grey[50],
          border: '1.5px solid rgba(31, 73, 94, 0.15)',
          boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
          borderRadius: '4px',
          '& .MuiInput-underline, & .MuiInput-underline:hover': {
            '&::after,&::before': {
              borderBottom: 'none'
            }
          },
          [theme.breakpoints.down('md')]: {
            py: 0
          }
        })}
      >
        <InputLabel
          sx={(theme) => ({
            color: '#5E7A8C',
            top: '5px',
            left: theme.spacing(1),
            '&.MuiFormLabel-root.MuiFormLabel-focused': {
              color: '#5E7A8C'
            }
          })}
        >
          {t('plantSelection.selectPlant', 'Select plant')}
        </InputLabel>
        <Select
          sx={{
            '&.MuiOutlinedInput-root': {
              padding: 0,
              fontWeight: 500
            }
          }}
          onChange={(event) => {
            onChange(event.target.value)
          }}
          value={activePlantId}
          disableUnderline
        >
          {plants?.map((detail) => (
            <MenuItem key={detail.plantId} value={detail.plantId}>
              {detail.plantName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
