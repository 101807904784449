import {parseIsoDateStringToDate} from '@hconnect/common/dates/ISO8601Helpers'
import {timeZoneTimeToLocalTime, localTimeToTimeZoneTime} from '@hconnect/common/dates/timeZone'
import {TFunction} from 'i18next'
import {toLower} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {TimeZone, Iso8601, Dates, isSameDate} from '../../../common'

import {QuickSelectionItem, SelectionItem} from './types'

export const QUICK_SELECTION_IDS = [
  'today',
  'yesterday',
  'last3days',
  'last7days',
  'last30days',
  'last3months'
] as const
export type QuickSelectionId = (typeof QUICK_SELECTION_IDS)[number]

export const defaultQuickSelectionItems = (
  t: TFunction,
  timezone: TimeZone,
  now = new Date()
): SelectionItem<QuickSelectionId>[] => {
  const startOfToday: Moment = moment.utc(now).tz(timezone).startOf('day')
  const endOfToday: Moment = startOfToday.clone().endOf('day')
  const startOfYesterday: Moment = startOfToday.clone().subtract(1, 'day')

  return [
    {
      id: 'today',
      title: t('quickSelection.today'),
      dates: [startOfToday.toDate(), endOfToday.toDate()]
    },
    {
      id: 'yesterday',
      title: t('quickSelection.yesterday'),
      dates: [startOfYesterday.toDate(), startOfYesterday.clone().endOf('day').toDate()]
    },
    {
      id: 'last3days',
      title: t('quickSelection.last3days'),
      dates: [startOfToday.clone().subtract(3, 'day').toDate(), endOfToday.toDate()]
    },
    {
      id: 'last7days',
      title: t('quickSelection.last7days'),
      dates: [startOfToday.clone().subtract(7, 'day').toDate(), endOfToday.toDate()]
    },
    {
      id: 'last30days',
      title: t('quickSelection.last30days'),
      dates: [startOfToday.clone().subtract(30, 'day').toDate(), endOfToday.toDate()]
    },
    {
      id: 'last3months',
      title: t('quickSelection.last3months'),
      dates: [startOfToday.clone().subtract(3, 'month').toDate(), endOfToday.toDate()]
    }
  ]
}

const PARAM_REGEX = /^\[(\S*),(\S*)]$/

export const parseIsoString = (dateString?: Iso8601): Date | undefined => {
  if (!dateString) {
    return undefined
  }
  const date = parseIsoDateStringToDate(dateString)
  return isNaN(+date) ? undefined : date
}

export const selectionToDateRange = (
  selectionItem?: QuickSelectionItem
): [Date | null, Date | null, string | null] =>
  selectionItem
    ? [selectionItem.dates[0] ?? null, selectionItem.dates[1] ?? null, selectionItem.id ?? null]
    : [null, null, null]

export const timeRangeParamToDateRange = <T extends string = string>({
  timeRange,
  selectionItems
}: {
  timeRange?: string
  selectionItems: SelectionItem<T>[]
}): [Date | null, Date | null, string | null] => {
  if (!timeRange) {
    return [null, null, null]
  }
  const selectedItem = selectionItems.find(({id}) => toLower(timeRange) === toLower(id))
  if (selectedItem) {
    return selectionToDateRange(selectedItem)
  }

  const match = PARAM_REGEX.exec(timeRange)
  const startDate = parseIsoString(match?.[1])
  const endDate = parseIsoString(match?.[2])

  return [startDate ?? null, endDate ?? null, null]
}

export const timeRangeParamToLocalTime = <T extends string = string>({
  timeRange,
  timezone,
  selectionItems
}: {
  timeRange?: string
  timezone: TimeZone
  selectionItems: SelectionItem<T>[]
}): [Date | null, Date | null] => {
  const [start, end] = timeRangeParamToDateRange({timeRange, selectionItems})
  return [
    start ? timeZoneTimeToLocalTime(start, timezone) : null,
    end ? timeZoneTimeToLocalTime(end, timezone) : null
  ]
}

export const timeRangeParamToSelectedItem = <T extends string = string>({
  timeRange,
  selectionItems
}: {
  timeRange?: string
  selectionItems: SelectionItem<T>[]
}): QuickSelectionItem | undefined => {
  if (!timeRange) {
    return undefined
  }
  return selectionItems.find(({id}) => toLower(timeRange) === toLower(id))
}

export const localDateTimeRangeToTimeRangeParam = (
  range: [Date | null, Date | null],
  timezone: TimeZone
): string | undefined => {
  const [start, end] = range
  if (!start) {
    return undefined
  }
  const startDate = localTimeToTimeZoneTime(start, timezone)
  const generatedEnd = end
    ? localTimeToTimeZoneTime(end, timezone)
    : moment.utc(startDate).tz(timezone).endOf('day')
  return `[${startDate.toJSON()},${generatedEnd.toJSON()}]`
}

export const selectionItemInLocalTime = (
  selectionItem: QuickSelectionItem,
  timezone: TimeZone
): QuickSelectionItem => {
  const [start, end] = selectionItem.dates
  return {
    ...selectionItem,
    dates: [
      timeZoneTimeToLocalTime(start, timezone),
      end ? timeZoneTimeToLocalTime(end, timezone) : undefined
    ]
  }
}

export const isSameDay = ([start, end]: [Date | null, Date | null]): boolean =>
  isSameDate(start, end)

/**
 * The end should always be the end of the day.
 * So this function takes a range and modifies the end so it is the end of the day
 * @param range
 * @return range that consist of two dates, first is start of day, second is end of day
 */
export const sanitizeLocalDateRange = (range: Dates<Date>): [Date | null, Date | null] => [
  range.startDate,
  range.endDate ? moment(range.endDate).endOf('day').toDate() : null
]
