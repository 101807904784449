import React, {useEffect, useState} from 'react'

import {DefaultLoadingComponent} from './DefaultLoadingComponent'

const DEFAULT_DELAY_MS = 400
const DEFAULT_TIMEOUT_MS = 200

interface LoadingSwitcherProps {
  delay?: number
  timeout?: number
  loadingComponent?: React.ReactElement
  isLoading: boolean
  testId?: string
  onTimeout?: () => void
  onLoad?: () => void
  variant?: 'overlay' | 'inline'
  children: React.ReactNode
}

/**
 * This component does a conditional render of its children or a loading component based on a condition that indicates if it is loading or not.  The loading state is only switched to after a timeout.  An optional callback is fired after a timeout has elapsed.
 * @param delay - the delay before the loading component is shown - defaults to 400 ms
 * @param timeout - the timeout - defaults to 10000 ms
 * @param testId - an optional override for the `data-test-id` attribute (for use in cypress or other tests)
 * @param onTimeout - callback fired after the timeout
 * @param onLoad - callback fired when the loading has finished
 * @param variant - whether to show the loading component `on top` of the children or in place of them
 * @param loadingComponent - the component that is shown as a loading indicator.  Defaults to a transparent black backdrop with a spinner
 * @param isLoading - boolean value that controls whether the loading screen is shown or not
 * @constructor
 */
export const LoadingSwitcher: React.FC<LoadingSwitcherProps> = ({
  isLoading,
  delay = DEFAULT_DELAY_MS,
  timeout = DEFAULT_TIMEOUT_MS,
  loadingComponent: originalLoader,
  children,
  testId = 'loading-switcher',
  onLoad,
  variant = 'inline',
  onTimeout
}) => {
  const [showLoading, setShowLoading] = useState<boolean>(false)

  const loader = originalLoader ? (
    React.cloneElement(originalLoader, {'data-test-id': testId})
  ) : (
    <DefaultLoadingComponent data-test-id={testId} isLoading={showLoading} />
  )

  // Ensure the failure function runs or is cleared
  useEffect(() => {
    const failureTimeout = setTimeout(() => onTimeout?.(), timeout)
    if (!isLoading) {
      clearTimeout(failureTimeout)
    }
  }, [isLoading, onTimeout, timeout])

  // Manage the currently rendered component based on the loading status
  useEffect(() => {
    let loadingTimeout: NodeJS.Timeout | null = null
    const clearLoadingTimeout = () => {
      if (loadingTimeout) clearTimeout(loadingTimeout)
    }

    if (isLoading) {
      loadingTimeout = setTimeout(() => setShowLoading(true), delay)
    }
    if (!isLoading) {
      loadingTimeout = setTimeout(() => setShowLoading(false), delay)
    }
    return clearLoadingTimeout
  }, [isLoading, delay, children, onLoad])

  // Run the `onLoad` callback
  const [hasFired, setHasFired] = useState(false)
  useEffect(() => {
    if (!isLoading && !hasFired) {
      onLoad?.()
      setHasFired(true)
    }
  }, [isLoading, onLoad, hasFired])

  const OverlayComponent = (
    <>
      {showLoading ? loader : null}
      {children}
    </>
  )

  const InlineComponent = <>{showLoading ? loader : children}</>
  return variant === 'inline' ? InlineComponent : OverlayComponent
}

LoadingSwitcher.displayName = 'LoadingSwitcher'
