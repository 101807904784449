import {Box, useTheme} from '@mui/material'
import {isNil} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState, useRef, KeyboardEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {Dates, isSameDate, IsoWeekDay} from '../../../common'
import {useActiveElement} from '../../hooks/useActiveElement'

import {BUTTON_SIZE, DateButton} from './DateButton'
import {isAfter, isBetweenDates} from './helpers'
import {MonthSelector} from './MonthSelector'

const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
] as const

interface CalendarProps {
  autoFocus?: boolean
  dateExceptions?: Date[]
  endDate?: Date | null
  focusEndDate?: boolean
  isOutsideRange?: (arg: Date) => boolean
  onDateChange?: (date: Date | null) => void
  onRangeChange?: (dates: Dates<Date>) => void
  startDate: Date | null
  weekDayLabel?: (day: IsoWeekDay) => string
}

enum ArrowKeys {
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown'
}

const add1 = (n: number) => n + 1
const subtract1 = (n: number) => n - 1

interface CreateConfigProps {
  dateExceptions?: Date[]
  endDate?: Date | null
  isOutsideRange?: (arg: Date) => boolean
  hoverDate: Date | null
  mapFn: (day: number, i: number) => number
  month: number
  startDate: Date | null
  total: number
  year: number
}

const isDateDisabled = (date: Date, exceptions?: Date[], isOutsideRange?: (arg: Date) => boolean) =>
  Boolean(exceptions?.some((d) => isSameDate(date, d)) || isOutsideRange?.(date))

const getNextDate = (date: Date): Date => {
  const nextDate = new Date(date)
  nextDate.setDate(nextDate.getDate() + 1)
  return nextDate
}
const getPreviousDate = (date: Date): Date => {
  const nextDate = new Date(date)
  nextDate.setDate(nextDate.getDate() - 1)
  return nextDate
}

const createButtonConfig = ({
  dateExceptions,
  endDate,
  hoverDate,
  isOutsideRange,
  mapFn,
  month,
  startDate,
  total,
  year
}: CreateConfigProps) =>
  Array.from(Array(total).keys()).map((_day, i) => {
    const day = mapFn(_day, i)
    const date = new Date(year, month, day)
    const nextDate = getNextDate(date)
    const previousDate = getPreviousDate(date)
    const isEndDate = isSameDate(date, endDate)
    const isNextDayDisabled = isDateDisabled(nextDate, dateExceptions, isOutsideRange)
    const isPreviousDayDisabled = isDateDisabled(previousDate, dateExceptions, isOutsideRange)
    const isDisabled = isDateDisabled(date, dateExceptions, isOutsideRange)
    const isStartDate = isSameDate(date, startDate)
    const isHoverDate = isSameDate(date, hoverDate)
    const inDisabledRange = [isDisabled, isNextDayDisabled, isPreviousDayDisabled].every(Boolean)
    const isDisabledRangeEnd = [isDisabled, !isNextDayDisabled, isPreviousDayDisabled].every(
      Boolean
    )
    const isDisabledRangeStart = isDisabled && isNextDayDisabled && !isPreviousDayDisabled
    const inRange = isBetweenDates(startDate, date, endDate)
    const selected = Boolean(isStartDate || isEndDate)
    const sorted =
      hoverDate && startDate
        ? [hoverDate, startDate].sort((a, b) => a.valueOf() - b.valueOf())
        : [startDate]

    const inRangeToHoverDate = [!endDate, isBetweenDates(sorted[0], date, sorted[1])].every(Boolean)

    const isRangeStart = endDate
      ? isStartDate
      : selected
      ? isAfter(hoverDate, startDate)
      : isHoverDate && isAfter(startDate, date)

    const isRangeEnd = endDate
      ? isEndDate
      : selected
      ? hoverDate && isAfter(startDate, hoverDate)
      : isHoverDate && isAfter(date, startDate)

    return {
      day,
      inDisabledRange,
      inRange: Boolean(inRange || inRangeToHoverDate),
      isDisabled,
      isDisabledRangeStart,
      isDisabledRangeEnd,
      isRangeEnd: Boolean(isRangeEnd),
      isRangeStart: Boolean(isRangeStart),
      month,
      selected,
      year
    }
  })

const Calendar: React.FC<CalendarProps> = ({
  autoFocus,
  dateExceptions,
  endDate,
  focusEndDate,
  isOutsideRange,
  onDateChange,
  onRangeChange,
  startDate,
  weekDayLabel
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  const activeElement = useActiveElement()
  const dateInView = focusEndDate && endDate ? endDate : startDate || new Date()
  const calendarRef = useRef<null | HTMLDivElement>(null)
  const [monthInView, setMonthInView] = useState(dateInView.getMonth())
  const [yearInView, setYearInView] = useState(dateInView.getFullYear())
  const [hoverDate, setHoverDate] = useState<Date | null>(null)
  const nextMonthInView = add1(monthInView)
  const isJanuary = monthInView === 0
  const isDecember = monthInView === 11
  const currentDate = new Date()
  const firstDayOfMonthInView = new Date(yearInView, monthInView, 1).getDay()
  const lastDayOfMonthInView = new Date(yearInView, nextMonthInView, 0).getDay()
  const lastDateOfMonthInView = new Date(yearInView, nextMonthInView, 0).getDate()
  const lastDateOfPreviousMonth = new Date(yearInView, monthInView, 0).getDate()
  const daysOfThisMonth = useMemo(
    () =>
      createButtonConfig({
        dateExceptions,
        endDate,
        isOutsideRange,
        mapFn: add1,
        month: monthInView,
        hoverDate,
        startDate,
        total: lastDateOfMonthInView,
        year: yearInView
      }),
    [
      dateExceptions,
      endDate,
      isOutsideRange,
      monthInView,
      lastDateOfMonthInView,
      hoverDate,
      startDate,
      yearInView
    ]
  )

  const daysOfNextMonth = useMemo(
    () =>
      lastDayOfMonthInView > 0
        ? createButtonConfig({
            dateExceptions,
            endDate,
            isOutsideRange,
            mapFn: add1,
            month: isDecember ? 0 : nextMonthInView,
            hoverDate,
            startDate,
            total: 7 - lastDayOfMonthInView,
            year: isDecember ? add1(yearInView) : yearInView
          })
        : [],
    [
      dateExceptions,
      endDate,
      isDecember,
      isOutsideRange,
      lastDayOfMonthInView,
      nextMonthInView,
      hoverDate,
      startDate,
      yearInView
    ]
  )

  const daysOfLastMonth = useMemo(
    () =>
      firstDayOfMonthInView !== 1
        ? createButtonConfig({
            dateExceptions,
            endDate,
            isOutsideRange,
            mapFn: (_, i) => lastDateOfPreviousMonth - i,
            month: isJanuary ? 11 : subtract1(monthInView),
            hoverDate,
            startDate,
            total: firstDayOfMonthInView === 0 ? 6 : subtract1(firstDayOfMonthInView),
            year: isJanuary ? subtract1(yearInView) : yearInView
          }).reverse()
        : [],
    [
      dateExceptions,
      endDate,
      firstDayOfMonthInView,
      isJanuary,
      isOutsideRange,
      lastDateOfPreviousMonth,
      monthInView,
      hoverDate,
      startDate,
      yearInView
    ]
  )

  const selectDate = useCallback(
    (day: number, month: number, year: number) => {
      const selected = new Date(year, month, day)
      const sorted = startDate
        ? [selected, startDate].sort((a, b) => a.valueOf() - b.valueOf())
        : [selected]
      const start = endDate ? selected : sorted[0] // always set the earliest date as start date
      const end = endDate ? null : sorted[1] // and latest as end date
      onDateChange?.(selected) ||
        onRangeChange?.({
          startDate: start,
          endDate: end
        })
    },
    [endDate, onDateChange, onRangeChange, startDate]
  )

  const handleHover = useCallback(
    (day: number, month: number, year: number) => {
      if (!startDate || onDateChange) {
        return
      }
      const hoverDate = new Date(year, month, day)
      setHoverDate(hoverDate)
    },
    [onDateChange, setHoverDate, startDate]
  )

  const showNextMonth = useCallback(() => {
    if (monthInView === 11) {
      setYearInView(add1(yearInView))
      setMonthInView(0)
    } else {
      setMonthInView(add1(monthInView))
    }
  }, [monthInView, setMonthInView, setYearInView, yearInView])

  const showPreviousMonth = useCallback(() => {
    if (monthInView === 0) {
      setYearInView((year) => subtract1(year))
      setMonthInView(11)
    } else {
      setMonthInView((month) => subtract1(month))
    }
  }, [monthInView, setMonthInView, setYearInView])

  const handleKeyPress = useCallback(
    ({key}: KeyboardEvent) => {
      const {DOWN, UP, LEFT, RIGHT} = ArrowKeys
      if (isNil(calendarRef)) {
        return
      }

      if (![DOWN, UP, LEFT, RIGHT].includes(key as ArrowKeys)) {
        return
      }

      const goBack = key === LEFT || key === UP
      let totalMoves = [DOWN, UP].includes(key as ArrowKeys) ? 7 : 1 // Left or Right -> Move 1 day, Up or Down -> Move 1 week,
      let previousButton = activeElement?.previousSibling as HTMLButtonElement | null
      let nextButton = activeElement?.nextSibling as HTMLButtonElement | null

      while (totalMoves > 1) {
        nextButton = nextButton?.nextSibling as HTMLButtonElement | null
        previousButton = previousButton?.previousSibling as HTMLButtonElement | null
        totalMoves -= 1
      }

      const button = goBack ? previousButton : nextButton

      if (button) {
        button.focus()
      } else {
        goBack ? showPreviousMonth() : showNextMonth()
        // Need a timeout for the childNodes to get rendered
        setTimeout(() => {
          goBack
            ? (calendarRef.current?.lastChild as HTMLButtonElement)?.focus()
            : (calendarRef.current?.firstChild as HTMLButtonElement)?.focus()
        }, 0)
      }
    },
    [activeElement, showNextMonth, showPreviousMonth]
  )

  const removeHoverEndDate = useCallback(() => {
    setHoverDate(null)
  }, [setHoverDate])

  useEffect(() => {
    if (!isNil(calendarRef) && !isNil(calendarRef.current) && autoFocus) {
      ;(calendarRef.current.firstChild as HTMLButtonElement)?.focus()
    }
  }, [autoFocus, calendarRef])

  const weekDayLabelFunc =
    weekDayLabel ?? ((day: IsoWeekDay) => t(`datePicker.weekdaysShort.${day}`))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.primary,
        width: '100%'
      }}
    >
      <MonthSelector
        monthInView={monthInView}
        showNextMonth={showNextMonth}
        showPreviousMonth={showPreviousMonth}
        yearInView={yearInView}
      />

      <Box
        sx={{
          color: theme.palette.text.secondarySoft,
          display: 'flex',
          justifyContent: 'space-around',
          marginBottom: theme.spacing(2)
        }}
      >
        {WEEK_DAYS.map((day) => (
          <Box
            component="span"
            sx={{
              '&:first-of-type': {
                order: 7
              }
            }}
            key={day}
          >
            {weekDayLabelFunc(day)}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          width: BUTTON_SIZE * 7
        }}
        onBlur={removeHoverEndDate}
        onMouseOut={removeHoverEndDate}
        onKeyDown={handleKeyPress}
        ref={calendarRef}
      >
        {[daysOfLastMonth, daysOfThisMonth, daysOfNextMonth].map((dayBlocks, i) =>
          dayBlocks.map(
            ({
              day,
              inRange,
              inDisabledRange,
              isRangeEnd,
              isDisabled,
              isDisabledRangeStart,
              isDisabledRangeEnd,
              isRangeStart,
              month,
              selected,
              year
            }) => (
              <DateButton
                key={day}
                day={day}
                isCurrentDay={isSameDate(currentDate, new Date(year, month, day))}
                inDisabledRange={inDisabledRange}
                isDisabled={isDisabled}
                inRange={inRange}
                isDisabledRangeStart={isDisabledRangeStart}
                isDisabledRangeEnd={isDisabledRangeEnd}
                isCurrentMonth={i === 1}
                isRangeEnd={isRangeEnd}
                isRangeStart={isRangeStart}
                selected={selected}
                handleHover={() => handleHover(day, month, year)}
                handleSelect={() => selectDate(day, month, year)}
              />
            )
          )
        )}
      </Box>
    </Box>
  )
}

export {Calendar}
