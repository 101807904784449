import {Box, CircularProgress, BoxProps} from '@mui/material'
import React from 'react'

export const Loader: React.FC<BoxProps> = (props) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height={props.height ?? '100%'}
    {...props}
  >
    <CircularProgress sx={[Boolean(props.color) && {color: props.color}]} />
  </Box>
)
