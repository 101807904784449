import {getLanguageTitle} from '@hconnect/common/utils/language.util'
import {Box, Fade, Menu, MenuItem, Theme, useMediaQuery} from '@mui/material'
import {popoverClasses} from '@mui/material/Popover'
import {toUpper} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Flag} from '../Flag'

interface Props {
  availableLanguages: string[]
  selectLanguage: (languageCode: string) => void
  currentLanguage: string
  country?: string
}

export const LanguagePicker = ({
  availableLanguages,
  currentLanguage,
  country,
  selectLanguage
}: Props) => {
  const {t} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (language: string) => {
    handleClose()
    selectLanguage(language)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
        sx={{cursor: 'pointer'}}
        data-test-id={`displayed-language-${currentLanguage}`}
      >
        <Flag countryCode={country || toUpper(currentLanguage)} />
        {isWideScreen ? (
          <span style={{marginLeft: '10px'}}>
            {getLanguageTitle(currentLanguage, country) || t(`language.${currentLanguage}`)}
          </span>
        ) : null}
      </Box>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={(theme: Theme) => ({
          [`& .${popoverClasses.paper}`]: {
            [theme.breakpoints.up('md')]: {
              width: '100%'
            }
          }
        })}
      >
        {availableLanguages.map((language, index) => (
          <MenuItem
            value={language}
            key={index}
            onClick={() => handleSelect(language)}
            data-test-id={`language-item-${language}`}
          >
            <Box display="flex" alignItems="center">
              <Flag countryCode={country || currentLanguage} />
              <span style={{marginLeft: '10px'}}>
                {getLanguageTitle(language, country) || t(`language.${language}`)}
              </span>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
