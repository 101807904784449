import {Box, Button, TextField, useTheme} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState, ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {Dates} from '../../../common'

import {
  formatDate,
  formatTime,
  getDateObjectFromInput,
  getDateValue,
  getTimeValue,
  isDateValid
} from './helpers'

export interface PickerFormProps {
  applyDateRange: (dates: Dates<Date>) => void
  dateExceptions?: Date[]
  isOutsideRange?: (arg: Date) => boolean
  isSingleDate?: boolean
  endDate?: Date | null
  startDate: Date | null
  dateFormatter?: (d: Date) => string
  timeFormatter?: (d: Date) => string
}

const PickerForm: React.FC<PickerFormProps> = ({
  applyDateRange,
  endDate: _endDate,
  dateExceptions,
  isOutsideRange,
  isSingleDate,
  startDate: _startDate,
  dateFormatter = formatDate,
  timeFormatter = formatTime
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  const [startDate, setStartDate] = useState<string>(_startDate ? dateFormatter(_startDate) : '')
  const [endDate, setEndDate] = useState<string>(_endDate ? dateFormatter(_endDate) : '')
  const [startTime, setStartTime] = useState<string>(
    _startDate ? timeFormatter(_startDate) : '00:00'
  )
  const [endTime, setEndTime] = useState<string>(_endDate ? timeFormatter(_endDate) : '00:00')
  const isInputValid = useMemo(
    () =>
      isSingleDate
        ? isDateValid({
            date: startDate,
            time: startTime,
            exceptions: dateExceptions,
            isOutsideRange
          })
        : isDateValid({date: startDate, time: startTime, isOutsideRange}) &&
          isDateValid({date: endDate, time: endTime, isOutsideRange}) &&
          getDateObjectFromInput(endDate, endTime) > getDateObjectFromInput(startDate, startTime),
    [dateExceptions, endDate, endTime, isOutsideRange, isSingleDate, startDate, startTime]
  )

  const handleDateChange = useCallback(
    ({target}: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = getDateValue(target.value)
      target.name === 'startDate' ? setStartDate(value) : setEndDate(value)
    },
    [setStartDate]
  )

  const handleTimeChange = useCallback(
    ({target}: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = getTimeValue(target.value)
      target.name === 'startTime' ? setStartTime(value) : setEndTime(value)
    },
    [setStartTime]
  )

  const clearInput = useCallback(() => {
    setStartDate('')
    setEndDate('')
    setStartTime('')
    setEndTime('')
  }, [])

  const apply = useCallback(() => {
    applyDateRange({
      startDate: getDateObjectFromInput(startDate, startTime) || null,
      endDate: getDateObjectFromInput(endDate, endTime) || null
    })
  }, [applyDateRange, endDate, endTime, startDate, startTime])

  useEffect(() => {
    setStartDate(_startDate ? dateFormatter(_startDate) : '')
    setEndDate(_endDate ? dateFormatter(_endDate) : '')
  }, [_startDate, _endDate, dateFormatter])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        marginTop: 1
      }}
    >
      <Box
        data-test-id="time-range-inputs"
        sx={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}
      >
        <TextField
          variant="outlined"
          name="startDate"
          placeholder="09.01.2021"
          label={t('datePicker.startDate')}
          value={startDate}
          onChange={handleDateChange}
          InputProps={{
            autoComplete: 'off',
            sx: {
              width: 146,
              borderRadius: 1,
              height: 48,
              marginBottom: theme.spacing(2),
              flexShrink: 0,
              background: 'rgba(0,0,0,0.01)',
              '&input': {
                padding: '13px 13px 5px'
              },
              '&input:valid + fieldset > legend > span': {
                display: 'none'
              }
            }
          }}
          InputLabelProps={{
            sx: {
              color: theme.palette.text.primarySoft
            }
          }}
        />
        <TextField
          variant="outlined"
          name="startTime"
          placeholder="00:00"
          label={t('datePicker.startTime')}
          value={startTime}
          onChange={handleTimeChange}
          InputProps={{
            autoComplete: 'off',
            sx: {
              width: 146,
              borderRadius: 1,
              height: 48,
              marginBottom: theme.spacing(2),
              flexShrink: 0,
              background: 'rgba(0,0,0,0.01)',
              '&input': {
                padding: '13px 13px 5px'
              },
              '&input:valid + fieldset > legend > span': {
                display: 'none'
              }
            }
          }}
          InputLabelProps={{
            sx: {
              color: theme.palette.text.primarySoft
            }
          }}
        />
        {!isSingleDate && (
          <>
            <TextField
              variant="outlined"
              name="endDate"
              placeholder="09.01.2021"
              label={t('datePicker.endDate')}
              value={endDate}
              onChange={handleDateChange}
              InputProps={{
                autoComplete: 'off',
                sx: {
                  width: 146,
                  borderRadius: 1,
                  height: 48,
                  marginBottom: theme.spacing(2),
                  flexShrink: 0,
                  background: 'rgba(0,0,0,0.01)',
                  '&input': {
                    padding: '13px 13px 5px'
                  },
                  '&input:valid + fieldset > legend > span': {
                    display: 'none'
                  }
                }
              }}
              InputLabelProps={{
                sx: {
                  color: theme.palette.text.primarySoft
                }
              }}
            />
            <TextField
              variant="outlined"
              name="endTime"
              placeholder="00:00"
              label={t('datePicker.endTime')}
              value={endTime}
              onChange={handleTimeChange}
              InputProps={{
                autoComplete: 'off',
                sx: {
                  width: 146,
                  borderRadius: 1,
                  height: 48,
                  marginBottom: theme.spacing(2),
                  flexShrink: 0,
                  background: 'rgba(0,0,0,0.01)',
                  '&input': {
                    padding: '13px 13px 5px'
                  },
                  '&input:valid + fieldset > legend > span': {
                    display: 'none'
                  }
                }
              }}
              InputLabelProps={{
                sx: {
                  color: theme.palette.text.primarySoft
                }
              }}
            />
          </>
        )}
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <Button
          sx={{
            padding: 1,
            textTransform: 'none',
            '&:first-of-type': {
              marginRight: 2
            },
            color: theme.palette.primary.main
          }}
          onClick={clearInput}
          color={'secondary'}
          variant="outlined"
          data-test-id="range-delete"
        >
          {t('datePicker.delete')}
        </Button>
        <Button
          sx={{
            padding: 1,
            textTransform: 'none',
            '&:first-of-type': {
              marginRight: 20
            }
          }}
          onClick={apply}
          disabled={!isInputValid}
          color={'primary'}
          data-test-id="range-apply"
        >
          {t('datePicker.apply')}
        </Button>
      </Box>
    </Box>
  )
}

export {PickerForm}
