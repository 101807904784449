import {Box, BoxProps, Typography, SxProps, Theme} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import React from 'react'

import {LayoutConstants} from '../../common'
import {PageTitle} from '../components'
import {mergeSx} from '../utils'

const getBodySx = (variant?: string, sx: SystemStyleObject<Theme> = {}): SxProps<Theme> => {
  if (variant === 'sheet') {
    return (theme) => ({
      background: theme.palette.common.white,
      color: theme.palette.text.primary,
      ...sx
    })
  }
  if (variant === 'subPage') {
    return {p: 0, ...sx}
  }
  return sx
}

export interface PageProps extends Pick<BoxProps, 'px' | 'py'> {
  headerActionContent?: React.ReactNode
  beforeHeaderContent?: React.ReactNode
  title?: React.ReactNode
  aboveTitle?: React.ReactNode
  subTitle?: React.ReactNode
  children?: React.ReactNode
  variant?: 'default' | 'sheet' | 'subPage'
  boxed?: boolean
  className?: string
  sx?: SystemStyleObject<Theme>
  nameHeaderSx?: SxProps<Theme>
  nameBodySx?: SxProps<Theme>
  classNameActionContent?: string
  sxActionContent?: SxProps<Theme>
  'data-test-id'?: string
}

export const Page: React.FC<PageProps> = ({
  headerActionContent,
  beforeHeaderContent,
  title,
  aboveTitle,
  subTitle,
  children,
  variant = 'default',
  boxed = true,
  'data-test-id': dataTestId,
  nameHeaderSx,
  nameBodySx,
  classNameActionContent,
  sxActionContent,
  sx = {},
  py = 6,
  px = 2
}) => {
  const pageTitle = <PageTitle sx={{ml: 1, mb: 0}}>{title}</PageTitle>

  const secondaryTitle = (secondaryTitle?: React.ReactNode | string) => (
    <Typography variant="h4">{secondaryTitle}</Typography>
  )

  const pageTitleAndSubTitle = (subTitle: React.ReactNode | string) => (
    <Box sx={{mb: 2}}>
      <PageTitle sx={{mb: 0, ml: 0}}>{title}</PageTitle>
      {secondaryTitle(subTitle)}
    </Box>
  )

  const content = (
    <>
      {aboveTitle !== undefined && secondaryTitle(aboveTitle)}
      {(title || headerActionContent) && (
        <Box display="flex" justifyContent="space-between" alignItems="stretch" sx={nameHeaderSx}>
          {
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              {beforeHeaderContent && (
                <Box sx={[{display: 'flex', mr: 1}, Boolean(subTitle) && {mb: 2}]}>
                  {beforeHeaderContent}
                </Box>
              )}
              {subTitle ? pageTitleAndSubTitle(subTitle) : pageTitle}
            </Box>
          }
          {headerActionContent && (
            <Box sx={mergeSx({minHeight: 46}, sxActionContent)} className={classNameActionContent}>
              {headerActionContent}
            </Box>
          )}
        </Box>
      )}
      <Box sx={nameBodySx}>{children}</Box>
    </>
  )

  return (
    <Box px={px} py={py} flexGrow={1} sx={getBodySx(variant, sx)} data-test-id={dataTestId}>
      {boxed ? (
        <Box sx={{margin: '0 auto', maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH}}>
          {content}
        </Box>
      ) : (
        content
      )}
    </Box>
  )
}
