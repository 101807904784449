import {Button, ButtonOwnProps, SxProps, Theme, useMediaQuery, useTheme} from '@mui/material'
import React from 'react'

export type Props = Pick<ButtonOwnProps, 'variant'> & {
  label: string
  onClick(): Promise<void> | void
  'data-test-id'?: string
  sx?: SxProps<Theme>
  disabled?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
}

export const IconWithLabelButton: React.FC<Props> = ({
  label,
  onClick,
  'data-test-id': dataTestId,
  sx,
  disabled,
  startIcon,
  endIcon,
  variant
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Button
      disabled={disabled}
      onClick={() => {
        void onClick()
      }}
      sx={sx}
      variant={variant || 'text'}
      data-test-id={dataTestId}
      startIcon={!isSmall && startIcon}
      endIcon={!isSmall && endIcon}
    >
      {isSmall ? (startIcon ? startIcon : endIcon ? endIcon : label) : label}
    </Button>
  )
}
