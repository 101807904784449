import DateRangeIcon from '@mui/icons-material/DateRange'
import {Box, Button, ButtonProps, Tooltip, Typography, useTheme} from '@mui/material'
import React, {Ref} from 'react'

import {mergeSx} from '../../utils'

interface Props {
  focus: boolean
  buttonRef: Ref<HTMLButtonElement>
  setFocus: (focus: boolean) => void
  label: string
  isDateChange?: boolean
  isDateChangeLabel?: string
  simpleLayout?: boolean
  customButtonProps?: ButtonProps
}

export const DatePickerButton: React.FC<Props> = ({
  focus,
  buttonRef,
  setFocus,
  label,
  isDateChange,
  isDateChangeLabel,
  customButtonProps,
  simpleLayout = false
}) => {
  const {sx: customButtonSx, ...restCustomButtonProps} = customButtonProps ?? {}
  const buttonProps: ButtonProps = {
    variant: 'text',
    ref: buttonRef,
    id: 'date-picker-toggle-button',
    onClick: () => {
      setFocus(!focus)
    },
    ...restCustomButtonProps
  }
  const theme = useTheme()
  return (
    <Tooltip open={isDateChange} title={isDateChangeLabel ?? ''} placement="right">
      <Button
        {...buttonProps}
        sx={mergeSx(
          {
            ...(isDateChange && {
              border: `${theme.spacing(0.125)}px solid ${theme.palette.primary.main}`
            })
          },
          customButtonSx
        )}
      >
        {simpleLayout ? (
          label
        ) : (
          <Box display="flex" alignItems="center">
            <Typography variant="formLabel" color="primary">
              {label}
            </Typography>
            <Box mx={0.25} />
            <DateRangeIcon fontSize="inherit" color="primary" />
          </Box>
        )}
      </Button>
    </Tooltip>
  )
}
