import {ChevronLeft} from '@mui/icons-material'
import {Box, Link} from '@mui/material'
import React from 'react'

import {TextOnBackground} from '../typography'

interface BackToLinkProps {
  target: string
  targetName: string
  // function from react-router-dom v5 or v6 push or navigate
  navigate: (route: string) => void
}

export const BackToLink: React.FC<BackToLinkProps> = ({target, targetName, navigate}) => {
  return (
    <Link
      component="span"
      sx={{
        textDecoration: 'none'
      }}
      data-test-id={`back-to-link-${targetName}`}
      onClick={() => navigate(target)}
    >
      <TextOnBackground
        variant="body1"
        color="primary.contrastText"
        contrast="low"
        highlightOnHover
        mb={0.5}
      >
        <Box component="span" sx={(theme) => ({display: 'inline-block', width: theme.spacing(3)})}>
          <ChevronLeft sx={{verticalAlign: 'text-bottom'}} />
        </Box>
        {targetName}
      </TextOnBackground>
    </Link>
  )
}
