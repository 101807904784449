import {TextField} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import moment, {Moment} from 'moment-timezone'
import {useCallback, useEffect, useState, FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Iso8601} from '../../../common'

import {NumberSelect} from './NumberSelect'

type DateStepperProps = {
  dateUtc: Iso8601
  timezone: string
  dateUtcBound: [Iso8601, Iso8601]
  onChange?: (dateUtc: Iso8601) => void
  'data-test-id'?: string
}

export const DateStepper: FC<DateStepperProps> = ({
  dateUtc,
  timezone,
  dateUtcBound,
  onChange,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const [selectedDate, _setSelectedDate] = useState(moment.utc(dateUtc).tz(timezone))
  const [minDate, maxDate] = dateUtcBound.map((dateUtc) => moment.utc(dateUtc).tz(timezone))
  const [open, setOpen] = useState(false)

  useEffect(() => {
    _setSelectedDate(moment.utc(dateUtc).tz(timezone))
  }, [dateUtc, timezone])

  const setSelectedDate = useCallback(
    (date: Moment) => {
      _setSelectedDate(date)
      if (onChange) {
        onChange(date.toISOString())
      }
    },
    [onChange]
  )

  const getPreviousDate = useCallback(() => selectedDate.clone().subtract(1, 'day'), [selectedDate])
  const getNextDate = useCallback(() => selectedDate.clone().add(1, 'day'), [selectedDate])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NumberSelect
        title={t('dateStepper.label')}
        plusButtonProps={{
          onClick: () => setSelectedDate(getNextDate()),
          disabled: getNextDate().isAfter(maxDate, 'day'),
          'data-test-id': `${dataTestId}-plus`
        }}
        minusButtonProps={{
          onClick: () => setSelectedDate(getPreviousDate()),
          disabled: getPreviousDate().isBefore(minDate, 'day'),
          'data-test-id': `${dataTestId}-minus`
        }}
      >
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={selectedDate}
          onChange={(newValue) => {
            if (newValue && moment(newValue).isValid()) {
              setSelectedDate(moment(newValue))
            }
          }}
          minDate={minDate}
          maxDate={maxDate}
          inputFormat="DD.MM.YY"
          renderInput={({inputRef, inputProps}) => (
            <TextField
              data-test-id={dataTestId}
              ref={inputRef}
              inputProps={{...inputProps, readOnly: true}}
              onClick={() => setOpen(true)}
              sx={{
                width: '80px',
                backgroundColor: 'transparent',
                margin: 0,

                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '& .MuiInputBase-input': {
                  padding: 0,
                  cursor: 'pointer',
                  textAlign: 'center'
                }
              }}
            />
          )}
        />
      </NumberSelect>
    </LocalizationProvider>
  )
}
