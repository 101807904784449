import {KeyboardArrowDown, KeyboardArrowRight} from '@mui/icons-material'
import {Box, Collapse, IconButton, SxProps, TableCell, TableRow, Theme} from '@mui/material'
import React, {ElementType} from 'react'

import {mergeSx} from '../../utils'

import {Column} from './DataTable.types'

type DataTableRowProps<T> = {
  rowId?(item: T): string
  item: T
  keyExtractor?(item: T): string
  onRowClick?(event: React.MouseEvent, item: T, i: number): void
  columns: Column<T>[]
  rowIndex: number
  rowPropStyles?: SxProps<Theme>
  collapseContent?: ElementType<{rowData: T}>
  index: number
  toggleRow: (index: number) => void
  isOpen: boolean
  className?: string
}

export const DataTableRow = <T,>({
  rowId,
  item,
  onRowClick,
  keyExtractor,
  columns,
  className,
  rowPropStyles,
  index,
  toggleRow,
  isOpen,
  collapseContent: CollapseContent,
  rowIndex
}: DataTableRowProps<T>): JSX.Element => {
  const tableCellSx = CollapseContent ? {borderBottom: 'unset'} : {}

  return (
    <>
      <TableRow
        id={rowId?.(item)}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault()
          onRowClick && onRowClick(event, item, rowIndex)
        }}
        className={className}
        data-test-id={`DataTable-row-${keyExtractor ? keyExtractor(item) : rowIndex}`}
        aria-label={`DataTable row ${rowIndex}`}
        sx={rowPropStyles}
      >
        {CollapseContent && (
          <TableCell
            width={1}
            sx={{
              fontSize: 14,
              py: 2,
              ...tableCellSx
            }}
          >
            <IconButton
              size="small"
              onClick={() => toggleRow(index)}
              data-test-id={`table-row-expand-button-${index}`}
            >
              {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
        )}

        {columns.map((column, index) => {
          const align = column.align || 'left'
          return (
            <TableCell
              data-test-id={`DataTable-column-${column.key}${rowIndex}`}
              aria-label={`DataTable column ${column.key} ${rowIndex}-${index}`}
              key={`${keyExtractor ? keyExtractor(item) : ''}-${column.key}`}
              align={align}
              sx={mergeSx({pl: {xs: 1.5, sm: 2, ...tableCellSx}}, column.columnSx)}
            >
              {column.customTemplate ? column.customTemplate(item) : item[column.key]}
            </TableCell>
          )
        })}
      </TableRow>
      {CollapseContent && (
        <TableRow>
          <TableCell sx={{py: 0}} colSpan={6}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <CollapseContent rowData={item} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
