import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TimeZone, DateTime} from '../types'
import {formatDateLocale} from '../utils'

type DateFormatProps = {
  date: DateTime
  timezone: TimeZone
}

export const DateFormat: React.FC<DateFormatProps> = ({date, timezone}) => {
  const {
    i18n: {language}
  } = useTranslation()
  const momentDate = moment.tz(date, timezone)
  return <>{formatDateLocale(momentDate, language)}</>
}
