import {Button, SxProps, Theme, Tooltip} from '@mui/material'
import React from 'react'

import {buttonCheckedSx, buttonSx} from './styles'

const BUTTON_SIZE_IN_PX = 58

export const iconButtonSx: SxProps<Theme> = (theme) => ({
  minWidth: BUTTON_SIZE_IN_PX,
  minHeight: BUTTON_SIZE_IN_PX,
  ...buttonSx(theme)
})

export const iconButtonCheckedSx: SxProps<Theme> = (theme) => ({
  minWidth: BUTTON_SIZE_IN_PX,
  minHeight: BUTTON_SIZE_IN_PX,
  ...buttonCheckedSx(theme)
})

export type Props = {
  icon: React.ReactNode
  onClick: () => void
  title: string
  'data-test-id'?: string
  checked?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean
}

export const IconWithTooltipButton: React.FC<Props> = ({
  icon,
  onClick,
  'data-test-id': dataTestId,
  checked,
  disabled,
  title
}) => {
  return (
    <Tooltip arrow title={title} placement="top">
      <Button
        disabled={disabled}
        onClick={() => {
          void onClick()
        }}
        sx={checked ? iconButtonCheckedSx : iconButtonSx}
        data-test-id={dataTestId}
        data-state={checked ? 'checked' : 'unchecked'}
      >
        {icon}
      </Button>
    </Tooltip>
  )
}
