import {MoreVert, EditOutlined, DeleteOutlined} from '@mui/icons-material'
import {Menu, Typography, Divider, styled, MenuItem} from '@mui/material'
import React, {useState, useRef, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {LoadingIconButton} from './LoadingIconButton'

const StyledMenuItem = styled(MenuItem)`
  height: 48px;
`

const ICON_BUTTON_SIZE_IN_PX = 36

interface EditDeleteMoreButtonProps<T> {
  item: T
  onEdit?: (item: T) => void
  onDelete?: (item: T) => void
  loading?: boolean
  'data-test-id'?: string
}

export const EditDeleteMoreButton = <T extends {id: string | number}>({
  item,
  onEdit,
  onDelete,
  loading = false,
  'data-test-id': dataTestId
}: EditDeleteMoreButtonProps<T>) => {
  const {t} = useTranslation()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const menuButtonRef = useRef(null)

  const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen])
  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen])
  const onEditCallback = useCallback(() => {
    closeMenu()
    onEdit?.(item)
  }, [item, onEdit, closeMenu])
  const onDeleteCallback = useCallback(() => {
    closeMenu()
    onDelete?.(item)
  }, [item, onDelete, closeMenu])

  return (
    <>
      <LoadingIconButton
        data-test-id={dataTestId}
        ref={menuButtonRef}
        onClick={openMenu}
        color={'primary'}
        loading={loading}
        sx={{width: ICON_BUTTON_SIZE_IN_PX, height: ICON_BUTTON_SIZE_IN_PX}}
      >
        <MoreVert fontSize={'small'} />
      </LoadingIconButton>
      <Menu
        data-test-id={`more-menu-item-menu-${item.id}`}
        anchorEl={menuButtonRef.current}
        open={menuOpen}
        onClose={closeMenu}
      >
        <StyledMenuItem onClick={onEditCallback}>
          <Typography variant="subtitle1">
            <EditOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
            {t('button.edit')}
          </Typography>
        </StyledMenuItem>

        <Divider />

        <StyledMenuItem onClick={onDeleteCallback}>
          <Typography variant="subtitle1">
            <DeleteOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
            {t('button.delete')}
          </Typography>
        </StyledMenuItem>
      </Menu>
    </>
  )
}
