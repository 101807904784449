/**
 * Removes character that are not allowed in HTML attributes - use it for data-test-id etc.
 */
export const formatTestId = (value: string) => value.replace(/(\s|,|-|\.|\/|\(|\))/g, '_')

/**
 * Creates a spreadable object with a correctly formatted data test id
 */
export const dataTestId = (value: string | number): {'data-test-id': string} => ({
  'data-test-id': formatTestId(`${value}`)
})
