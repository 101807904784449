import {Clear, DeleteOutline} from '@mui/icons-material'
import {Dialog, DialogActions, DialogContent, Button, ButtonProps} from '@mui/material'
import React from 'react'

interface DeletionModalProps {
  open: boolean
  message: string
  cancelButtonCaption: string
  confirmButtonCaption: string
  onConfirm: () => void
  onCancel: () => void
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps
  cancelButtonTestId?: string
  confirmButtonTestId?: string
}

export const DeletionModal: React.FC<DeletionModalProps> = ({
  open,
  message,
  cancelButtonCaption,
  confirmButtonCaption,
  onCancel,
  onConfirm,
  cancelButtonProps,
  confirmButtonProps,
  cancelButtonTestId,
  confirmButtonTestId
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent sx={{minWidth: {sm: 400}}}>{message}</DialogContent>
      <DialogActions>
        <Button
          color={'secondary'}
          onClick={onCancel}
          startIcon={<Clear />}
          data-test-id={cancelButtonTestId}
          {...cancelButtonProps}
        >
          {cancelButtonCaption}
        </Button>
        <Button
          color={'error'}
          variant={'contained'}
          onClick={onConfirm}
          startIcon={<DeleteOutline />}
          data-test-id={confirmButtonTestId}
          {...confirmButtonProps}
        >
          {confirmButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
