export const formatFloat = (amount: number, digits = 2, language?: string): string => {
  if (language) {
    return parseFloat(amount.toFixed(digits)).toLocaleString(language)
  }
  return amount.toFixed(digits).toString()
}

export const formatTons = (amount: number, language?: string, unitOverride?: string): string =>
  `${formatFloat(amount, 0, language)} ${unitOverride || 't'}`

export const formatCurrency = formatFloat

export const formatMWh = (amount: number, language?: string, unitOverride?: string): string =>
  `${formatFloat(amount, 2, language)} ${unitOverride || 'MWh'}`

export const formatMW = (amount: number, language?: string, unitOverride?: string): string =>
  `${formatFloat(amount, 2, language)} ${unitOverride || 'MW'}`
