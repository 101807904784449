/**

Simple wrapper to wait setTimeout inside Promise 

*/

const asyncWait = (timeout: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, timeout)
  })
}

/**

Async function which would periodicaly call check function. 
Period of this call is checkInterval.

It stops after one of those two coditions passes:
a) It resolves immediatelly with return value true after check function has returned true
b) It stops after waitTimeout time has elapsed

*/
const waitForFunctionTruthy = async (
  check: () => boolean,
  checkInterval = 500,
  waitTimeout = 20000
) => {
  const start = new Date().getTime()

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // if element from querySelector appeared then successfully stop this function
    if (check()) {
      return true
    }

    // if element does not appear after waitTimeout then this function would stop
    if (start + waitTimeout < new Date().getTime()) {
      return false
    }
    await asyncWait(checkInterval)
  }
}

/**

Async function which would periodicaly call if element defined by selector exists. 
Period of this call is checkInterval.

It stops after one of those two coditions passes:
a) It resolves immediatelly with return value true after check function has returned true
b) It stops after waitTimeout time has elapsed

*/

export const waitForDOMElementToAppear = async (
  selector: string,
  checkInterval = 500,
  waitTimeout = 20000
) => waitForFunctionTruthy(() => !!document.querySelector(selector), checkInterval, waitTimeout)
