import {Home} from '@material-ui/icons'
import {ArrowBack} from '@mui/icons-material'
import {Link, Button, Stack} from '@mui/material'
import React from 'react'

import {buttonOnBlueSx} from '../components'

import {BaseErrorPage} from './BaseErrorPage'
import {ReactComponent as Illustration} from './illustration404.svg'

interface Error404PageProps {
  caption: string
  description: string
  goToCaption?: string
  goToLink?: string
  goToIcon?: React.ReactNode
  RouterLink: React.ElementType
  onGoBack?: () => void
  goBackCaption?: string
  dataTestId?: string
}

export const Error404Page: React.FC<Error404PageProps> = ({
  caption,
  description,
  goToCaption,
  goToLink,
  goToIcon,
  RouterLink,
  onGoBack,
  goBackCaption = 'Back to last page',
  dataTestId = 'error_404_page'
}) => (
  <BaseErrorPage
    caption={caption}
    description={description}
    illustration={<Illustration />}
    dataTestId={dataTestId}
  >
    <Stack direction="row" spacing={2}>
      {onGoBack && (
        <Button
          sx={buttonOnBlueSx}
          startIcon={<ArrowBack />}
          onClick={onGoBack}
          data-test-id={'error_page_404_go_back_btn'}
        >
          {goBackCaption}
        </Button>
      )}
      {goToCaption && goToLink && (
        <Link
          component={RouterLink}
          to={goToLink}
          sx={{
            textDecoration: 'none'
          }}
          data-test-id={`error-page-404-link-to-${goToLink}`}
        >
          <Button sx={buttonOnBlueSx} startIcon={goToIcon ?? <Home />}>
            {goToCaption}
          </Button>
        </Link>
      )}
    </Stack>
  </BaseErrorPage>
)
