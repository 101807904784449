import {Box, Theme} from '@mui/material'
import {sanitize} from 'dompurify'
import React from 'react'

export const LegalDocumentRenderer: React.FC<{body?: string | null}> = ({body}) => {
  if (!body) return null

  return (
    <Box
      sx={(theme: Theme) => ({
        '& h1': {
          fontSize: '1.5em',
          lineHeight: 'initial'
        },
        overflowX: 'hidden',
        padding: `0 ${theme.spacing(2)}px`
      })}
      data-test-id="legal-document-content"
      dangerouslySetInnerHTML={{__html: sanitize(body) ?? ''}}
    />
  )
}
