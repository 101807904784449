import moment, {Moment} from 'moment-timezone'

import {isSameDateMoment} from '../../../common'

import {timezonedDateValidator} from './dateRangePickerHelpers'

export const DATE_FORMAT_FROM_INPUT = 'DD.MM.YYYY'
export const TIME_FORMAT_FROM_INPUT = 'HH:mm'

export const getDateObjectFromInput = (date: string, time: string, timeZone: string): Moment => {
  // Combine date and time strings
  const dateTimeString = `${date} ${time}`
  // Parse the combined date and time string in the given or local time zone
  return moment.tz(dateTimeString, `${DATE_FORMAT_FROM_INPUT} ${TIME_FORMAT_FROM_INPUT}`, timeZone)
}

export const getDateValue = (val: string): string => {
  const value = val.replaceAll('.', '')
  const {day, month, year} = getDatePartsFromString(val)

  if (
    value.length > 10 ||
    (day !== '' && !isDayValid(day)) ||
    (month !== '' && !isMonthValid(month))
  ) {
    return val.slice(0, -1)
  }
  return `${day ?? ''}${month ? `.${month}` : ''}${year ? `.${year}` : ''}`
}

export const getTimeValue = (val: string): string => {
  const value = val.replaceAll(':', '').replace(/\D/g, '')
  const hours = value.substring(0, 2)
  const minutes = value.substring(2, 4)

  if (
    [minutes, hours].some((el) => el && isNaN(parseInt(el, 10))) ||
    value.length > 5 ||
    parseInt(minutes, 10) > 59 ||
    parseInt(hours, 10) > 23
  ) {
    return val.slice(0, -1)
  }
  if (hours.length === 1 && parseInt(hours, 10) > 2) {
    return `0${hours}`
  }
  return `${hours ? hours : ''}${minutes ? `:${minutes}` : ''}`
}

export const getDatePartsFromString = (val: string): {day: string; month: string; year: string} => {
  const value = val.replaceAll('.', '')
  const day = value.substring(0, 2)
  const month = value.substring(2, 4)
  const year = value.substring(4, 8)

  return {
    day: !isNaN(parseInt(day, 10)) ? day : '',
    month: !isNaN(parseInt(month, 10)) ? month.toString() : '',
    year: !isNaN(parseInt(year, 10)) ? year.toString() : ''
  }
}

export const getTimePartsFromString = (val: string): {minutes: string; hours: string} => {
  const [hours = '', minutes = ''] = val.split(':')
  return {minutes, hours}
}

export const isBetweenDates = (a?: Moment | null, b?: Moment, c?: Moment | null): boolean => {
  const timezone = a?.tz()
  if (timezone) {
    b && timezonedDateValidator(b, timezone)
    c && timezonedDateValidator(c, timezone)
  }
  return Boolean(a && b && c && b.isAfter(a) && b.isBefore(c))
}

export const isAfter = (a?: Moment | null, b?: Moment | null): boolean => {
  const timezone = a?.tz()
  if (timezone && b) timezonedDateValidator(b, timezone)
  return Boolean(a && b && a.isAfter(b))
}
export const isDayValid = (val?: string): boolean => Boolean(val && parseInt(val, 10) < 32)
export const isMonthValid = (val?: string): boolean => Boolean(val && parseInt(val, 10) < 13)
export const isYearValid = (val?: string): boolean => Boolean(val && parseInt(val, 10) > 2000)
export const isMinuteValid = (val?: string): boolean =>
  Boolean(val && val.length === 2 && parseInt(val, 10) < 60)
export const isHourValid = (val?: string): boolean =>
  Boolean(val && val.length === 2 && parseInt(val, 10) < 24)

interface DateValidProps {
  date: string
  time: string
  timezone: string
  exceptions?: Moment[]
  isOutsideRange?: (arg: Moment) => boolean
}

export const isDateValid = ({date, time, exceptions, isOutsideRange, timezone}: DateValidProps) => {
  const {day, month, year} = getDatePartsFromString(date)
  const {hours, minutes} = getTimePartsFromString(time)

  if (
    !isDayValid(day) ||
    !isMonthValid(month) ||
    !isYearValid(year) ||
    !isMinuteValid(minutes) ||
    !isHourValid(hours)
  ) {
    return false
  }

  const _date = moment({
    year: parseInt(year, 10),
    month: parseInt(month) - 1,
    day: parseInt(day)
  }).tz(timezone)
  return (
    _date.isValid() &&
    !exceptions?.some((d) => isSameDateMoment(_date, d)) &&
    !isOutsideRange?.(_date)
  )
}

export const formatTimeLocale = (date: Moment, language?: string): string =>
  language ? date.locale(language).format('LT') : date.format('LT')
