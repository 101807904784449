import {AppName, PlantId, WhiteList} from '@hconnect/common/hproduce/types'
import {Close, Menu} from '@mui/icons-material'
import {Box, Divider, MenuItem, MenuList, Tab, Tabs, Link, Badge} from '@mui/material'
import React, {useLayoutEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useNavigationLinkStyles} from '../../hooks'
import {AppSelect} from '../AppSelect'
import {HProduceDashboardLink} from '../HProduceDashboardLink'
import {MenuLinksBox} from '../MenuLinksBox'
import {Overlay} from '../Overlay'
import {ShellIconButton} from '../ShellIconButton'
import {NavItem} from '../types'

import {getIsLinkActive} from './helpers'

const tabSx = {
  textTransform: 'capitalize',
  fontWeight: 500,
  paddingX: 0,
  minWidth: 'unset'
}

enum BurgerMenuTabs {
  menu,
  products,
  settings
}

interface BurgerMenuProps {
  navItems?: NavItem[]
  disableAppSelect?: boolean
  activeAppName: AppName | 'HProduce'
  activePlantId?: PlantId
  whiteList?: WhiteList
  onNavItemClick: ((route: string) => void) | undefined
  // function from react-router-dom v5 or v6 push or navigate
  navigate: (route: string) => void
  locationPath: string
  id?: string
  'data-test-id'?: string
}

interface NavMenuLinksProps
  extends Pick<BurgerMenuProps, 'navItems' | 'navigate' | 'locationPath' | 'data-test-id'> {
  onSelect?: (selectedItem?: NavItem) => void
}

interface FlatNavItem extends Omit<NavItem, 'subItems'> {
  level: number
}

function flattenNavItems(navItems: NavItem[], level = 0): FlatNavItem[] {
  let items: FlatNavItem[] = []
  navItems.forEach((item) => {
    items.push({
      ...item,
      level
    })
    if (item.subItems && item.subItems.length > 0) {
      items = [...items, ...flattenNavItems(item.subItems, level + 1)]
    }
  })
  return items
}

const NavMenuLinks: React.FC<NavMenuLinksProps> = ({
  navItems = [],
  onSelect,
  navigate,
  'data-test-id': listDataTestId
}) => {
  const {link, activeLink, disabledLink} = useNavigationLinkStyles()

  const flatNavItems = useMemo(() => flattenNavItems(navItems), [navItems])

  return (
    <MenuList disablePadding data-test-id={listDataTestId}>
      {flatNavItems.map((navItem, index) => {
        const {label, id, url, dataTestId, isDisabled, level, activateOnlyIfExact} = navItem
        const isLinkActive = getIsLinkActive(url, window.location.pathname, activateOnlyIfExact)
        return (
          <Link
            key={label}
            onClick={() => {
              onSelect?.(navItem)
              navigate?.(url)
            }}
            style={{
              ...link,
              ...(isDisabled && disabledLink),
              ...(isLinkActive && activeLink)
            }}
            className={isLinkActive ? 'active' : undefined}
            data-test-id={dataTestId}
            id={id ?? `nav-item-${index}`}
          >
            <MenuItem
              sx={{
                ml: `${16 * level}px`,
                py: 2,
                '& +.MuiDivider-root': {
                  my: 0
                }
              }}
            >
              {navItem.showBadge ? (
                <Badge color="primary" variant="dot">
                  {navItem.label}
                </Badge>
              ) : (
                navItem.label
              )}
            </MenuItem>
            {index < flatNavItems.length - 1 && (
              <Divider
                orientation="horizontal"
                sx={{
                  mx: 2
                }}
              />
            )}
          </Link>
        )
      })}
    </MenuList>
  )
}

const getVisibleTabIndices = (
  hasNavItems: boolean,
  disableAppSelect: boolean
): BurgerMenuTabs[] => {
  const result: BurgerMenuTabs[] = hasNavItems ? [BurgerMenuTabs.menu] : []
  if (!disableAppSelect) result.push(BurgerMenuTabs.products)
  return result
}

const getDefaultTabIndex = (hasNavItems: boolean, disableAppSelect: boolean): number =>
  getVisibleTabIndices(hasNavItems, disableAppSelect)[0] ?? 0

const validTabIndexOrDefault = (
  tabIndex: number,
  hasNavItems: boolean,
  disableAppSelect: boolean
): number =>
  getVisibleTabIndices(hasNavItems, disableAppSelect).includes(tabIndex)
    ? tabIndex
    : getDefaultTabIndex(hasNavItems, disableAppSelect)

export const BurgerMenu: React.FC<BurgerMenuProps> = ({
  navItems = [],
  disableAppSelect = false,
  'data-test-id': dataTestId = 'burger-menu',
  id = 'burger-menu',
  activeAppName,
  activePlantId,
  whiteList,
  onNavItemClick,
  navigate,
  locationPath
}) => {
  const {t} = useTranslation()
  const [isOpen, setOpen] = useState<boolean>(false)
  const hasNavItems = navItems.length > 0
  const [tabValue, setTabValue] = useState<BurgerMenuTabs>(
    getDefaultTabIndex(hasNavItems, disableAppSelect)
  )

  useLayoutEffect(() => {
    setTabValue(getDefaultTabIndex(hasNavItems, disableAppSelect))
  }, [disableAppSelect, hasNavItems])

  const onClose = () => {
    setOpen(false)
  }

  if (disableAppSelect && !hasNavItems) {
    return null
  }

  const validatedTabValue = validTabIndexOrDefault(tabValue, hasNavItems, disableAppSelect)

  return (
    <>
      <ShellIconButton
        sx={(theme) => ({
          marginRight: 2,
          ...(isOpen ? {backgroundColor: theme.palette.grey[200], boxShadow: 'none'} : {})
        })}
        data-test-id={dataTestId}
        id={id}
        onClick={() => setOpen(!isOpen)}
        size="medium"
        focusRipple={isOpen}
        aria-label="toggle menu"
      >
        {isOpen ? <Close /> : <Menu />}
      </ShellIconButton>
      <Overlay
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            opacity: 0,
            pointerEvents: 'none'
          }
        })}
        id="burger-menu-overlay"
        isVisible={isOpen}
      >
        {!disableAppSelect && (
          <Box bgcolor="background.paper" color="text.primary" borderRadius={1} mt={3} mb={2} p={2}>
            <HProduceDashboardLink activePlantId={activePlantId} />
          </Box>
        )}
        <Tabs
          centered
          indicatorColor="primary"
          textColor="inherit"
          value={validatedTabValue}
          onChange={(e, v: number) => {
            setTabValue(v)
          }}
          sx={(theme) => ({
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.common.white
            },
            '& .MuiTabs-flexContainer': {
              gap: 6
            }
          })}
        >
          {hasNavItems && (
            <Tab value={BurgerMenuTabs.menu} label={t('burgerMenu.menu')} sx={tabSx} />
          )}
          {!disableAppSelect && (
            <Tab value={BurgerMenuTabs.products} label={t('burgerMenu.products')} sx={tabSx} />
          )}
        </Tabs>
        <Box height={24} />
        {hasNavItems && validatedTabValue === BurgerMenuTabs.menu && (
          <MenuLinksBox>
            <NavMenuLinks
              navItems={navItems}
              onSelect={(selectedItem) => {
                selectedItem && onNavItemClick?.(selectedItem.url)
                onClose()
              }}
              navigate={navigate}
              locationPath={locationPath}
              data-test-id={`${dataTestId}-list`}
            />
          </MenuLinksBox>
        )}
        {!disableAppSelect && whiteList && validatedTabValue === BurgerMenuTabs.products && (
          <AppSelect
            activeAppName={activeAppName}
            activePlantId={activePlantId}
            data-test-id={dataTestId}
            whiteList={whiteList}
          />
        )}
      </Overlay>
    </>
  )
}
