import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  monthInView: number
  showNextMonth: () => void
  showPreviousMonth: () => void
  yearInView: number
}

const MonthSelector: React.FC<Props> = ({
  monthInView,
  showNextMonth,
  showPreviousMonth,
  yearInView
}) => {
  const {t} = useTranslation()
  const text = `${t('datePicker.months', {returnObjects: true})[monthInView]} ${yearInView}`

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 1
      }}
    >
      <IconButton onClick={showPreviousMonth}>
        <ChevronLeftIcon />
      </IconButton>
      {text}
      <IconButton onClick={showNextMonth}>
        <ChevronRightIcon />
      </IconButton>
    </Box>
  )
}

export {MonthSelector}
