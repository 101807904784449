import {Tab, Tabs} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  setShowCalendar: (show: boolean) => void
  showCalendar: boolean
}

const TabBar: React.FC<Props> = ({setShowCalendar, showCalendar}) => {
  const {t} = useTranslation()

  return (
    <Tabs
      value={showCalendar ? 1 : 0}
      onChange={(event: React.SyntheticEvent, newValue: number) => {
        setShowCalendar(Boolean(newValue))
      }}
    >
      <Tab value={0} label={t('datePicker.tabQuickSelect')} data-test-id={'tab-quick-select'} />
      <Tab value={1} label={t('datePicker.tabCustom')} data-test-id={'tab-custom'} />
    </Tabs>
  )
}

export {TabBar}
