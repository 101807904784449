import {Compress, Expand} from '@mui/icons-material'
import {ButtonProps, Button} from '@mui/material'
import React from 'react'

type ExpandCollapseButtonProps = {
  expandLabel?: string
  collapseLabel?: string
  expanded: boolean
} & ButtonProps

export const ExpandCollapseButton: React.FC<ExpandCollapseButtonProps> = ({
  expandLabel = 'Expand',
  collapseLabel = 'Collapse',
  expanded,
  onClick,
  ...rest
}) => (
  <Button onClick={onClick} startIcon={expanded ? <Compress /> : <Expand />} {...rest}>
    {expanded ? collapseLabel : expandLabel}
  </Button>
)
