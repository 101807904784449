import {TableCellProps, TableCell, Theme, Collapse} from '@mui/material'
import React from 'react'

import {mergeSx} from '../../utils'

export const CollapsableTableCell: React.FC<TableCellProps & {open?: boolean}> = ({
  open = false,
  children,
  sx,
  ...rest
}) => (
  <TableCell
    sx={mergeSx(
      (theme: Theme) => ({
        transition: `padding ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
        ...(open ? {} : {border: 0, py: 0})
      }),
      sx
    )}
    {...rest}
  >
    <Collapse in={open}>{children}</Collapse>
  </TableCell>
)
