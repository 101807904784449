import {appUrl, hproduce} from '@hconnect/common/hproduce/config'
import {AppName, PlantId, WhiteList} from '@hconnect/common/hproduce/types'
import {Apps} from '@mui/icons-material'
import {Popover, Link, Typography, Box, Divider} from '@mui/material'
import {orderBy} from 'lodash'
import React, {SyntheticEvent, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useIsMobile} from '../hooks'

import {HProduceDashboardLink} from './HProduceDashboardLink'
import {MenuLinksBox} from './MenuLinksBox'
import {PlantSelect} from './PlantSelect'
import {ProductSelect} from './ProductSelect'
import {ShellIconButton} from './ShellIconButton'

type AppSelectProps = {
  activeAppName?: AppName | 'HProduce' | undefined
  activePlantId?: PlantId | undefined
  'data-test-id'?: string
  whiteList: WhiteList
}

export const AppSelect: React.FC<AppSelectProps> = ({
  activeAppName,
  activePlantId,
  'data-test-id': dataTestId = 'select',
  whiteList
}) => {
  const {t} = useTranslation()
  const isMobile = useIsMobile()
  const {optionsPerPlant} = whiteList

  const allPlants = useMemo(
    () =>
      orderBy(
        Object.keys(optionsPerPlant).map((plantId) => ({
          plantId,
          plantName: optionsPerPlant[plantId][0]?.plantName
        })),
        'plantName'
      ),
    [optionsPerPlant]
  )

  const [plantId, setPlantId] = useState(
    activePlantId ||
      (activeAppName &&
        activeAppName !== 'HProduce' &&
        whiteList.optionsPerApp[activeAppName]?.[0].plantId) ||
      allPlants[0].plantId
  )

  const handlePlantIdChange = useCallback(
    (id: string) => {
      const {url} = optionsPerPlant[id].find(({appName}) => appName === activeAppName) || {}
      setPlantId(id)
      if (url && activePlantId !== id) {
        window.location.replace(url)
      }
    },
    [activeAppName, activePlantId, optionsPerPlant]
  )

  return (
    <>
      {allPlants.length > 1 && (
        // show the app select only in case there are multiple plants available
        <MenuLinksBox>
          <PlantSelect
            activePlantId={plantId}
            data-test-id={`${dataTestId}-plant`}
            onChange={handlePlantIdChange}
            plants={allPlants}
          />
        </MenuLinksBox>
      )}
      <MenuLinksBox>
        {!isMobile && (
          <>
            <HProduceDashboardLink activePlantId={plantId} />
            <Divider orientation="horizontal" sx={(theme) => ({margin: theme.spacing(0, 2)})} />
          </>
        )}
        <ProductSelect
          data-test-id={`${dataTestId}-product`}
          activeAppName={activeAppName}
          activePlantId={activePlantId}
          options={optionsPerPlant[plantId] ?? []}
        />
        <Divider orientation="horizontal" sx={(theme) => ({margin: theme.spacing(0, 2)})} />
        <Box p={2}>
          <Link underline={'none'} href={`${appUrl(hproduce.url)}/goDigital`}>
            <Typography variant={'subtitle2'} align={'center'}>
              {t('burgerMenu.more', 'More HProduce Products')}
            </Typography>
          </Link>
        </Box>
      </MenuLinksBox>
    </>
  )
}

type AppSelectPopUpProps = AppSelectProps & {
  'data-test-id'?: string
}

export const AppSelectPopUp: React.FC<AppSelectPopUpProps> = ({
  'data-test-id': dataTestId = 'grid-menu-icon',
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>()

  const handleClick = (event: SyntheticEvent<EventTarget>) =>
    setAnchorEl(event.currentTarget as HTMLInputElement)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <ShellIconButton
        sx={(theme) => ({
          mr: 2,
          ...(anchorEl ? {backgroundColor: theme.palette.grey[200], boxShadow: 'none'} : {})
        })}
        data-test-id={dataTestId}
        onClick={handleClick}
        size="medium"
        aria-label="toggle apps"
        id="burger-menu"
      >
        <Apps />
      </ShellIconButton>
      <Popover
        anchorEl={anchorEl}
        sx={{
          '& .MuiPopover-paper': {
            mt: 2.5,
            minWidth: '300px'
          }
        }}
        data-test-id={`${dataTestId}-popover`}
        onClose={handleClose}
        open={!!anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <AppSelect {...rest} data-test-id={`${dataTestId}-select`} />
      </Popover>
    </>
  )
}
