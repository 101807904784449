import {Box, Typography, useTheme} from '@mui/material'
import React from 'react'

export const PercentPieChart: React.FC<{
  percent: number
  text?: string
  'data-test-id'?: string
}> = ({percent, text, 'data-test-id': dataTestId = text}) => {
  const theme = useTheme()
  const strokeWidth = 10
  const radius = 50
  const dimension = 120
  const circumference = 2 * Math.PI * radius
  const strokeDasharray = `${circumference} ${circumference}`
  const strokeDashoffset = circumference - (percent / 100) * circumference
  const color =
    percent >= 100
      ? theme.palette.success.dark
      : percent > 80
      ? theme.palette.success.light
      : percent > 50
      ? theme.palette.warning.main
      : theme.palette.error.main

  return (
    <Box
      sx={{
        position: 'relative',
        width: `${dimension}px`,
        height: `${dimension}px`
      }}
      data-test-id={dataTestId}
    >
      <svg width={dimension} height={dimension} style={{transform: 'rotate(-90deg)'}}>
        <circle
          style={{
            fill: 'none',
            stroke: theme.palette.grey[200]
          }}
          cx="60"
          cy="60"
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          style={{
            fill: 'none',
            stroke: color,
            transition: 'stroke-dashoffset 0.3s ease-in-out'
          }}
          cx="60"
          cy="60"
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }}
      >
        <Typography
          variant="body2"
          color={color}
          data-test-id={`${dataTestId}-percent`}
        >{`${percent.toFixed(1)}%`}</Typography>
        {text && <Typography variant="caption">{text}</Typography>}
      </Box>
    </Box>
  )
}
