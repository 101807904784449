export const getIsLinkActive = (
  linkPath: string,
  currentLocationPath: string,
  mustBeExact = false
) => {
  const currentLocationPathWithoutTrailingSlash = currentLocationPath
    .split('?')[0]
    .replace(/\/+$/, '')
  const linkPathWithoutTrailingSlash = linkPath.split('?')[0].replace(/\/+$/, '')

  const isDirectRoute = currentLocationPathWithoutTrailingSlash === linkPathWithoutTrailingSlash
  const isSubRoute = `${currentLocationPathWithoutTrailingSlash}/`.includes(
    `${linkPathWithoutTrailingSlash}/`
  )
  return mustBeExact ? isDirectRoute : isDirectRoute || isSubRoute
}
