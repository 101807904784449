import moment, {Moment} from 'moment-timezone'

export const isSameDate = (a?: Date | null, b?: Date | null): boolean =>
  Boolean(a && b && (a === b || moment(a).isSame(b, 'date')))

export const isSameDateMoment = (a: Moment | null, b?: Moment | null): boolean => {
  if (a && b && a.tz() !== b.tz()) {
    throw new Error('Timezones are different')
  }
  return !!(a && b && a.isSame(b, 'date'))
}

export const isSameDayAcrossTimezones = (
  utcDate1: Moment,
  utcDate2: Moment,
  timezone: string
): boolean =>
  isSameDateMoment(utcDate1.tz(timezone).startOf('day'), utcDate2.tz(timezone).startOf('day'))

export const isSameMonthAcrossTimezones = (
  date1: Moment,
  date2: Moment,
  timezone: string
): boolean => {
  const normalizedDate1 = moment(date1).tz(timezone)
  const normalizedDate2 = moment(date2).tz(timezone)
  return normalizedDate1.isSame(normalizedDate2, 'month')
}
