import {Grid, Box, Link, Typography} from '@mui/material'
import React from 'react'

import {ReactComponent as LogoHProduceNoColorComponent} from '../../../common/assets/LogoHProduceNoColor.svg'
import {TextOnBackground} from '../../components'

import {OssAttributionLink} from './OssAttributionLink'

export type FooterLink = {
  label: string
  link: string
}
export type ShellFooterProps = {
  label: string
  copyrightNote?: string
  logo?: React.ReactNode
} & (
  | {
      footerLinks: FooterLink[]
      // function from react-router-dom v5 or v6 push or navigate
      navigate: (route: string) => void
    }
  | {
      footerLinks?: never
      navigate?: never
    }
)

export const ShellFooter: React.FC<ShellFooterProps> = ({
  label,
  logo,
  copyrightNote = `\u00a9 ${new Date().getFullYear()} HeidelbergMaterials. All rights reserved.`,
  footerLinks = [],
  navigate
}) => (
  <Box px={{xs: 1, sm: 2, md: 3, lg: 4}} py={{xs: 2, md: 4, lg: 4}} data-test-id="shell-footer">
    <Grid container justifyContent="flex-end" flexDirection={{xs: 'row', md: 'row-reverse'}}>
      <Grid
        item
        md={6}
        xs={12}
        lg={4}
        sx={{
          textAlign: {xs: 'center', sm: 'left', md: 'right'}
        }}
      >
        <TextOnBackground variant="caption" contrast="low">
          {label}
          <Box
            component="span"
            display="inline-flex"
            ml={'6px'}
            sx={{
              verticalAlign: 'text-bottom'
            }}
          >
            {logo ? (
              logo
            ) : (
              <Box component="span" display="inline-flex" mb={'1px'}>
                <LogoHProduceNoColorComponent height={14} />
              </Box>
            )}
          </Box>
        </TextOnBackground>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        lg={4}
        sx={{
          textAlign: {xs: 'center', sm: 'left', lg: 'center'}
        }}
      >
        <OssAttributionLink />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
        lg={4}
        sx={{
          textAlign: {xs: 'center', sm: 'left'}
        }}
      >
        <TextOnBackground variant="caption" contrast="low">
          {copyrightNote}
        </TextOnBackground>
      </Grid>
    </Grid>
    {footerLinks && (
      <Grid data-test-id="footer-links">
        {footerLinks.map(({label, link}) => {
          return (
            <Link
              key={label}
              component="span"
              onClick={() => navigate?.(link)}
              mr={2}
              underline="hover"
              color="primary.contrastText"
            >
              <Typography component="span" variant="body2">
                {label}
              </Typography>
            </Link>
          )
        })}
      </Grid>
    )}
  </Box>
)
