import {KeyboardArrowRight, KeyboardArrowDown} from '@mui/icons-material'
import {
  IconButton,
  Table as MaterialTable,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  SxProps,
  TableCellProps,
  Theme
} from '@mui/material'
import {get, isEmpty, isUndefined, uniqueId} from 'lodash'
import React, {ElementType} from 'react'

export type ColumnsConfig<T> = {
  key: string
  label: string | JSX.Element
  template?: ElementTypeWithRowData<T>
  headerSx?: SxProps<Theme>
  cellProps?: TableCellProps
}[]

type TableRowData<T> = {rowData: T}

export type ElementTypeWithRowData<T> = ElementType<TableRowData<T>>

type CollapsibleTableWithoutPaginationProps<T> = {
  data: T[]
  config: ColumnsConfig<T>
  collapseContent?: ElementTypeWithRowData<T>
  expandAll?: boolean
  expandedRows?: number[]
  setExpandedRows?: (id: number) => void
  emptyMessage?: string
}

type RowProps<T> = {
  config: ColumnsConfig<T>
  item: T
  collapseContent?: ElementTypeWithRowData<T>
  index: number
  toggleRow?: (index: number) => void
  isOpen: boolean
}

export function Row<T>({
  isOpen,
  toggleRow,
  config,
  item,
  collapseContent: CollapseContent,
  index
}: RowProps<T>) {

  return (
    <>
      <TableRow
        data-test-id={`tr-${index}`}
        sx={{
          '&:last-child td': {
            borderBottom: 0
          }
        }}
      >
        {CollapseContent && (
          <TableCell
            width={1}
            sx={{
              fontSize: 14,
              py: 2
            }}
          >
            <IconButton
              size="small"
              onClick={() => toggleRow?.(index)}
              data-test-id={`table-row-expand-button-${index}`}
            >
              {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
        )}

        {config.map(({template: Template, key, cellProps}) => {
          const node = get(item, key)
          const isTemplate = !isUndefined(Template)

          return (
            <TableCell
              {...cellProps}
              key={`table-cell-${key}-${uniqueId()}`}
              sx={{
                fontSize: 14,
                py: 2
              }}
            >
              {isTemplate ? <Template rowData={item} /> : node}
            </TableCell>
          )
        })}
      </TableRow>

      {CollapseContent && isOpen && <CollapseContent rowData={item} />}
    </>
  )
}

/**
 * Table component uses MUI table and supports expandable rows without pagination
 * @param CollapsibleTableWithoutPaginationProps
 * @returns
 */
export function CollapsibleTableWithoutPagination<T>({
  data,
  config,
  collapseContent: CollapseContent,
  expandAll,
  expandedRows,
  setExpandedRows,
  emptyMessage
}: CollapsibleTableWithoutPaginationProps<T>) {
  const isCollapseContent = CollapseContent && !isEmpty(data)
  return (
    <TableContainer>
      <MaterialTable>
        <TableHead>
          <TableRow>
            {isCollapseContent && <TableCell />}
            {config.map(({label, headerSx, key}) => (
              <TableCell sx={headerSx} key={`table-header-${key}`}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data) ? (
            data.map((item, index) => (
              <Row<T>
                collapseContent={CollapseContent}
                config={config}
                item={item}
                key={uniqueId('table-row-')}
                index={index}
                toggleRow={setExpandedRows}
                isOpen={expandAll ? expandAll : (expandedRows || []).includes(index)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={config.length}
                data-test-id="collapsible-table-empty-message"
                sx={{
                  textAlign: 'center'
                }}
              >
                {emptyMessage}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MaterialTable>
    </TableContainer>
  )
}
