import {Backdrop, BackdropProps, CircularProgress} from '@mui/material'
import React from 'react'

type DefaultLoadingComponentProps = {isLoading: boolean} & Omit<BackdropProps, 'className' | 'open'>
export const DefaultLoadingComponent: React.FC<DefaultLoadingComponentProps> = ({
  isLoading,
  ...backdropProps
}) => {
  return (
    <Backdrop
      sx={(theme) => ({zIndex: theme.zIndex.drawer + 1})}
      open={isLoading}
      {...backdropProps}
    >
      <CircularProgress />
    </Backdrop>
  )
}
